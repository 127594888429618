/* eslint-disable react/jsx-props-no-spreading */
import dayjs from 'dayjs';
import { render } from 'react-dom';
import analytics from 'react-ga';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { App, Connector } from './containers';
import { store } from './store';

import { toastConfig } from '~config/toasts';

import 'dayjs/locale/nl';
import 'react-toastify/dist/ReactToastify.css';

dayjs.locale('nl');

if (process.env.NODE_ENV === 'production') {
  analytics.initialize('UA-148179461-2');
}

render(
  <Provider store={store}>
    <Router>
      <ToastContainer {...toastConfig} />
      <Connector>
        <App />
      </Connector>
    </Router>
  </Provider>,
  document.getElementById('p2000scanner_app'),
);
