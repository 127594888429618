import { fade, makeStyles } from '@material-ui/core';

import { LOGO_ASPECT_RATIO } from '~common/variables';

/**
 * The styles which are used by the `Navbar` component
 */
export default makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(1.5),
    },
  },
  logo: {
    width: (<number>theme.mixins.toolbar.minHeight / 1.2) * LOGO_ASPECT_RATIO,
    height: <number>theme.mixins.toolbar.minHeight / 1.2,
    backgroundImage: `url(/static/images/logo-${
      theme.palette.type === 'dark' ? 'white' : 'black'
    }.png)`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  sidebarMenu: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.secondary.main, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.secondary.main, 0.25),
    },
    margin: theme.spacing(0, 1),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3),
      width: '50ch',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));
