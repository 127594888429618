import { makeStyles } from '@material-ui/core';

/**
 * The styles which are used by the `FilterSelector` component
 */
export default makeStyles({
  root: {
    width: '100%',
  },
});
