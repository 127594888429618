import { Marker } from 'react-leaflet';
import { useHistory } from 'react-router-dom';

import { getMeldingLocation } from '~utils';

/**
 * The property types which are used by the `LiveMeldingMarkers` component
 */
type LiveMeldingMarkersProps = {
  liveMeldingen: Record<string, any>[];
};

/**
 * A component that renders a list of "LiveMeldingMarkers"
 *
 * @param props The standard properties which are always available
 * @param props.meldingen The data to iterate through and display a "melding" for each record
 *
 * @returns The `MeldingList` component
 */
export function LiveMeldingMarkers({
  liveMeldingen,
}: LiveMeldingMarkersProps): JSX.Element {
  const history = useHistory();
  return (
    <>
      {liveMeldingen.map((melding) => {
        const locatie = melding ? getMeldingLocation(melding) : undefined;
        if (locatie?.latitude !== undefined) {
          return (
            <Marker
              key={melding._id}
              position={[locatie?.latitude, locatie?.longitude]}
              onclick={() => history.push(`/melding/${melding.hoofdmeldingId}`)}
            />
          );
        }
        return null;
      })}
    </>
  );
}
