/**
 * A hook that provides access to environment variables
 *
 * @param variable The environment variable which should be loaded
 *
 * @returns The `useVariable` hook
 */
export function useVariable(variable: EnvironmentVariable): string {
  const parsed = `REACT_APP_${variable}`;

  if (!process.env[parsed]) {
    throw new Error(
      `A value for the variable ${variable} was not provided, 
      are you sure you put the key in the environment file as ${parsed}?`,
    );
  }

  return process.env[parsed] as string;
}
