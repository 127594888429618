import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@material-ui/core';
import { LatLng } from 'leaflet';
import { TileLayer, Map } from 'react-leaflet';

import useStyles from './ContactPage.style';

/**
 * A page that displays in-depth details about a "contact"
 *
 * @param props The standard properties which are always available
 *
 * @returns The `ContactPage` page
 */
export function ContactPage() {
  const classes = useStyles();
  const theme = useTheme();

  /**
   * The position of the marker to use
   */
  const markerPosition = new LatLng(51.99428909219551, 5.967364860449136);

  /**
   * The url to use when the application is in light mode
   */
  const LIGHT_THEME_URL =
    'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png';

  /**
   * The url to use when the application is in dark mode
   */
  const DARK_THEME_URL =
    'https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png';

  return (
    <Container className={classes.root}>
      <Paper className={classes.view} variant="elevation">
        <Grid container alignItems="stretch" spacing={2}>
          <Grid item xs={12} md={12} xl={12}>
            <Paper variant="outlined" className={classes.map}>
              <Map
                center={markerPosition}
                zoom={12}
                minZoom={8}
                className={classes.map}
              >
                <TileLayer
                  url={
                    theme.palette.type === 'light'
                      ? LIGHT_THEME_URL
                      : DARK_THEME_URL
                  }
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | Map: &copy; <a href="https://carto.com/">Carto</a>'
                />
              </Map>
            </Paper>
          </Grid>
          <Grid container justify="center" className={classes.page}>
            <Box>
              <Typography className={classes.column} variant="h4">
                Wie zijn wij?
              </Typography>
            </Box>
            <Box mt={2}>
              <Divider />
            </Box>
            <Box mt={2}>
              <Typography variant="body1">
                P2000Scanner is een website waar men live de P2000 meldingen uit
                zijn/haar regio kan bekijken! Onze site maakt gebruik van
                geavanceerde technieken om de meldingen zo snel mogelijk op het
                scherm te krijgen. Het doel van de website is dus ook om de
                informatie zo snel en eenvoudig mogelijk op scherm te zetten.
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant="body1">
                Naast het bekijken van de live meldingen is er ook de
                mogelijkheid om bepaalde statistieken te zien van de dag en om
                meldingen te delen op social media. In de toekomst komt er nog
                een uitbreiding zodat de meldingen ook ontvangen kunnen worden
                op je mobiele telefoon.
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant="body1">
                Tips/Verbeter punten of wil je ons wat anders laten weten? Stuur
                ons dan een{' '}
                <a
                  href={`mailto:info@mundaro.nl?subject=Contact%formulier%|%P2000Scanner\n`}
                >
                  email!
                </a>{' '}
              </Typography>
            </Box>
          </Grid>
          <Grid container justify="center">
            <Box mt={7} mb={2} className={classes.copyright}>
              <p>
                Copyright &copy; {new Date().getFullYear()} P2000Scanner. Alle
                rechten voorbehouden.
              </p>
              <p>P2000Scanner is een product van Mundaro</p>
              <a
                href="https://mundaro.nl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://mundaro.nl/img/logo.png"
                  alt="Mundaro Logo"
                  className={classes.logo}
                />
              </a>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
