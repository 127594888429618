import { Box, Button, Typography } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { Meta } from '~components';

/**
 * A page that displays that an entity has not been found
 *
 * @param props The standard properties which are always available
 *
 * @returns The `NotFoundPage` page
 */
export function NotFoundPage(): JSX.Element {
  const history = useHistory();

  /**
   * Handles the redirection of the user
   */
  const handleRedirect = () => history.replace('/');

  return (
    <>
      <Meta
        title="P2000Scanner | Pagina niet gevonden"
        description="Whoeps, deze pagina is niet gevonden!"
        image="https://p2000scanner.nl/static/images/cover.jpg"
        twitterName="@ScannerP2000"
        type="page"
      />
      <Box
        minWidth="inherit"
        minHeight="inherit"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
      >
        <Typography variant="h5" component="h1">
          Uh oh! Deze pagina is niet gevonden...
        </Typography>
        <Box my={2} />
        <Button variant="outlined" onClick={handleRedirect}>
          Terug naar de homepagina <ArrowRight />
        </Button>
      </Box>
    </>
  );
}
