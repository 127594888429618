import { makeStyles } from '@material-ui/core';

const FOOTER_HEIGHT = 100;

/**
 * The styles which are used by the `MeldingDetailsPage` page
 */
export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  page: {
    padding: theme.spacing(2),
  },
  column: {
    fontWeight: theme.typography.fontWeightBold,
  },
  map: {
    minHeight: 400,
    height: '100%',
  },
  view: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  logo: {
    height: (theme.mixins.toolbar.minHeight as number) / 1.5,
  },
  copyright: {
    height: FOOTER_HEIGHT,
    alignItems: 'center',
    color: '#7c7c7d',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '& p': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(1, 0),
    },
  },
}));
