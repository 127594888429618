/* eslint-disable no-unused-vars */
import { ReactNode, useEffect } from 'react';

import { ungzip } from 'pako';

import { setLiveMeldingen } from './live.slice';

import { useAppDispatch, useStream } from '~hooks';

/**
 * The property types which are used by the `Melding` wrapper
 */
interface LiveMeldingWrapperProps {
  children: ReactNode;
}

/**
 * A wrapper that provides the values, logic and data for the `Live` feature
 *
 * @param props The standard properties which are always available
 * @param props.children The child element(s) which should be rendered within the wrapper
 *
 * @returns The `Live` wrapper
 */
export function LiveMeldingWrapper({
  children,
}: LiveMeldingWrapperProps): JSX.Element {
  const { stream } = useStream();
  const dispatch = useAppDispatch();

  /**
   * Parses meldingen and sets them to the state
   * @param data The gzipped data for meldingen to set
   */
  const liveMeldingHandler = (data: Uint8Array) => {
    const parsed = JSON.parse(ungzip(data, { to: 'string' }));
    dispatch(setLiveMeldingen(parsed));
  };

  /**
   * Change "live meldingen" data whenever the stream emits an update
   */
  useEffect(() => {
    stream.on('@@stream/meldingen-today', (data) => liveMeldingHandler(data));
    return () => {
      stream.off('@@stream/meldingen-today');
    };
  }, []);

  return <>{children}</>;
}
