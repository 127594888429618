import { ReactNode, useEffect } from 'react';

import { toast } from 'react-toastify';

import { useStream } from '~hooks';

/**
 * The property types which are used by the `Connector` container
 */
interface ConnectorProps {
  children: ReactNode;
}

/**
 * A container that handles connection to the back-end streaming service
 *
 * @param props The standard properties which are always available
 * @param props.children The child element(s) which should be rendered within the wrapper
 *
 * @returns The `Connector` container
 */
export function Connector({ children }: ConnectorProps): JSX.Element {
  const { stream, connect, disconnect } = useStream();

  /**
   * A hook that handles the connecting and disconnecting of the stream
   */
  useEffect(() => {
    connect();

    /**
     * Handle stream error
     */
    stream.on('error', () =>
      toast.error(
        'Er is een interne fout opgetreden tijdens het maken van een live verbinding.',
      ),
    );
    return () => {
      disconnect();
    };
  }, []);

  return <>{children}</>;
}
