import { Box, Typography } from '@material-ui/core';
import { ErrorOutline as ErrorIcon } from '@material-ui/icons';

import useStyles from './UnavailableItems.style';

/**
 * The property types which are used by the `UnavailableItems` component
 */
interface UnavailableItemsProps {
  message?: string;
}

/**
 * The default property values which are used by the `UnavailableItems` component
 */
const defaultProps: Partial<UnavailableItemsProps> = {
  message: 'Er zijn geen items gevonden',
};

/**
 * A component that handles the display and puts a message when a condition is not met
 *
 * @param props The standard properties which are always available
 * @param props.message The message to display when something is unavailable
 *
 * @returns The `UnavailableItems` component
 */
export function UnavailableItems({
  message,
}: UnavailableItemsProps): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <ErrorIcon className={classes.icon} />
      <Typography variant="body1">{message}</Typography>
    </Box>
  );
}

/**
 * Assign the default properties to the `UnavailableItems` component
 */
UnavailableItems.defaultProps = defaultProps;
