import {
  HomeWorkTwoTone as DisciplineIcon,
  HomeWork as DisciplineIconSelected,
  HomeWorkOutlined as DisciplineIconUnselected,
  MapTwoTone as RegioIcon,
  Map as RegioIconSelected,
  MapOutlined as RegioIconUnselected,
  HelpTwoTone as DefaultIcon,
  Help as DefaultIconSelected,
  HelpOutline as DefaultIconUnselected,
} from '@material-ui/icons';

import { FilterType } from '~models/stream';

/**
 * A type for the Icon format
 */
export type Icon = typeof DefaultIcon;

/**
 * A type for structuring a set of icons
 */
type IconSet = {
  main: Icon;
  selected: Icon;
  unselected: Icon;
};

/**
 * Gets the icon for the filter type
 * @param filterType The filter type to get an icon for
 * @returns The icon which belongs to the requested filter type
 */
export const getFilterIcon = (filterType: FilterType): IconSet => {
  switch (filterType) {
    case 'filterDiscipline': {
      return {
        main: DisciplineIcon,
        selected: DisciplineIconSelected,
        unselected: DisciplineIconUnselected,
      };
    }
    case 'filterRegio': {
      return {
        main: RegioIcon,
        selected: RegioIconSelected,
        unselected: RegioIconUnselected,
      };
    }
    default: {
      return {
        main: DefaultIcon,
        selected: DefaultIconSelected,
        unselected: DefaultIconUnselected,
      };
    }
  }
};
