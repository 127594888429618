import {
  Box,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@material-ui/core';
import {
  Close as CloseSidebarIcon,
  Block as UnavailableIcon,
} from '@material-ui/icons';
import { Link, matchPath, useLocation } from 'react-router-dom';

import useStyles from './SidebarContent.style';

import { routes } from '~config/routes';
import { closeSidebar } from '~features';
import { useAppDispatch } from '~hooks';

/**
 * A component that contains the content (like navigation items) used by the sidebar
 *
 * @param props The standard properties which are always available
 *
 * @returns The `SidebarContent` component
 */
export function SidebarContent(): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();

  /**
   * Checks if the route visited by the client is currently active
   * @param route The route which the client is currently on
   * @returns If the current route is active
   */
  const handleIsRouteActive = (route: Route): boolean =>
    !!matchPath(location.pathname, {
      path: route.path,
      exact: route.exact,
    });

  /**
   * Handles the closing of the sidebar
   */
  const handleClose = () => dispatch(closeSidebar());

  return (
    <nav>
      <Hidden mdUp>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          p={1.5}
        >
          <CloseSidebarIcon fontSize="large" onClick={handleClose} />
        </Box>
      </Hidden>
      <Hidden smDown>
        <Toolbar />
      </Hidden>
      <List>
        {routes.map((route) => {
          const isActive = handleIsRouteActive(route);

          return (
            route.displayLocation === 'sidebar' && (
              <ListItem
                button
                key={route.path}
                component={Link}
                to={route.path}
                selected={isActive}
              >
                <ListItemIcon>
                  {route.icon ? (
                    <route.icon color={isActive ? 'secondary' : 'action'} />
                  ) : (
                    <UnavailableIcon color="disabled" />
                  )}
                </ListItemIcon>
                <ListItemText
                  className={classes.menuTitle}
                  style={{ opacity: isActive ? 1 : 0.7 }}
                >
                  {route.displayName}
                </ListItemText>
              </ListItem>
            )
          );
        })}
      </List>
    </nav>
  );
}
