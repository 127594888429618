import { makeStyles } from '@material-ui/core';

/**
 * The styles which are used by the `LiveMapPage` page
 */
export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  map: {
    minHeight: 1230,
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
    '& [class^="leaflet-control-zoom-"]': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
  },
}));
