import { Theme as MuiTheme } from '@material-ui/core';

import { DARK_THEME } from './dark.theme';
import { LIGHT_THEME } from './light.theme';

/**
 * The different themes which are allowed
 */
export type ThemeType = 'LIGHT' | 'DARK';

/**
 * An interface which defines how a theme should be structured
 */
export interface Theme {
  label: string;
  theme: MuiTheme;
}

/**
 * A collection of themes which can be used throughout the application
 */
export const THEMES: Record<ThemeType, Theme> = {
  LIGHT: {
    label: 'Licht',
    theme: LIGHT_THEME,
  },
  DARK: {
    label: 'Donker',
    theme: DARK_THEME,
  },
};
