import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

/**
 * An interceptor to automatically handle toast messages on error
 *
 * @param error The Axios response to handle
 *
 * @returns The modified axios response
 */
export function useResponseInterceptorError(error: any): AxiosResponse<any> {
  if (error.response) {
    if (error.response.status === 404) {
      toast.error('Er is een fout opgetreden, dit object is niet gevonden.');
    } else {
      toast.error(
        'Er is een interne fout opgetreden, neem contact op met de systeembeheerder.',
      );
    }
  }

  return error;
}
