import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Typography,
} from '@material-ui/core';

import useStyles from './VehicleCard.style';

import { getVehicleImage, getVehicleName } from '~utils';

/**
 * The property types which are used by the `VehicleCard` component
 */
interface VehicleCardProps {
  vehicle: Record<string, any>;
  priority: string;
}

/**
 * A component that displays a vehicle and it's properties as a card
 *
 * @param props The standard properties which are always available
 * @param props.vehicle The data of the vehicle to use
 * @param props.priority The priority of the "melding" for the image type
 *
 * @returns The `VehicleCard` component
 */
export function VehicleCard({
  vehicle,
  priority,
}: VehicleCardProps): JSX.Element {
  const classes = useStyles();

  /**
   * The image to use for the vehicle
   */
  const vehicleImage = getVehicleImage(vehicle, priority);

  return (
    <Card className={classes.root} variant="elevation" elevation={2}>
      <CardHeader
        className={classes.header}
        title={
          getVehicleName(vehicle.naam, vehicle.afkorting) || 'Onbekend voertuig'
        }
        titleTypographyProps={{
          className: classes.title,
          variant: 'h6',
        }}
        subheader={vehicle.roepnummer || 'Onbekend roepnummer'}
      />

      <CardMedia className={classes.media} image={vehicleImage.path} />
      <CardContent className={classes.content}>
        <Box>
          <Typography variant="overline">Standplaats</Typography>
          <Typography variant="body1">
            {vehicle.standplaats || 'Onbekend'}
          </Typography>
          <>
            <Typography variant="overline">Opmerkingen</Typography>
            <Typography variant="body1">
              {vehicle.opmerkingen || 'Geen opmerkingen'}
            </Typography>
          </>
        </Box>
        {vehicleImage.author && (
          <Box textAlign="right" className={classes.details}>
            <Typography variant="caption" className={classes.caption}>
              Foto door: {vehicleImage.author}
            </Typography>
          </Box>
        )}
      </CardContent>
      <Divider />
      <CardActions>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <a
            className={classes.link}
            href={vehicle.infoLink}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              color="primary"
              variant="outlined"
              disabled={!vehicle.infoLink}
            >
              Meer informatie
            </Button>
          </a>
        </Box>
      </CardActions>
    </Card>
  );
}
