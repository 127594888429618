import { io, Socket } from 'socket.io-client';

import { useVariable } from './useVariable';

/**
 * The action property types which are used by the `useStream` hook
 */
interface useStreamActions {
  stream: Socket;
  isConnected: boolean;
  connect: () => void;
  disconnect: () => void;
}

/**
 * The Websocket instance which is used to communicate with the stream
 */
const stream = io(useVariable('STREAM_URI'), { autoConnect: false });

/**
 * A boolean which stores if a user is connected
 */
let isConnected = false;

/**
 * A hook that provides access to the back-end stream service
 *
 * @returns The `useStream` hook
 */
export function useStream(): useStreamActions {
  /**
   * Connects the client to the stream
   */
  const connect = (): void => {
    stream.connect();
    isConnected = true;
  };

  /**
   * Disconnects the client from the stream
   */
  const disconnect = (): void => {
    stream.disconnect();
    isConnected = false;
  };

  return { stream, isConnected, connect, disconnect };
}
