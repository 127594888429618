import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

import { GLOBAL_STYLES } from './globals';

/**
 * The light theme used throughout the application
 */
export const LIGHT_THEME = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: '#111',
      },
      secondary: {
        main: '#121212',
      },
      background: {
        default: '#f0f0f0',
        paper: '#f8f8f8',
      },
      text: {
        primary: '#111',
      },
      type: 'light',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          ...GLOBAL_STYLES('light'),
        },
      },
    },
  }),
);
