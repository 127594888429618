export interface PrivacySection {
  title: string;
  description: string[];
}

export const PRIVACY: PrivacySection[] = [
  {
    title: 'Waarborgen Privacy',
    description: [
      'Het waarborgen van de privacy van bezoekers van P2000Scanner.nl is een belangrijke taak voor ons. Daarom beschrijven we in onze privacy policy welke informatie we verzamelen en hoe we deze informatie gebruiken.',
    ],
  },
  {
    title: 'Toestemming',
    description: [
      'Door de informatie en de diensten op P2000Scanner.nl te gebruiken, gaat u akkoord met onze privacy policy en de voorwaarden die wij hierin hebben opgenomen.',
    ],
  },
  {
    title: 'Vragen',
    description: [
      'Indien u meer informatie wilt ontvangen en/of vragen hebt over de privacy policy van P2000Scanner.nl of specifiek P2000Scanner.nl, kunt u ons benaderen via e-mail. Ons e-mailadres is info@P2000Scanner.nl.',
    ],
  },
  {
    title: 'Monitoren gedrag bezoeker',
    description: [
      'P2000Scanner.nl maakt gebruik van verschillende technieken om bij te houden wie de website bezoekt, hoe deze bezoeker zich op de website gedraagt en welke pagina’s worden bezocht. Dit is een gebruikelijke manier voor websites omdat het informatie oplevert op die bijdraagt aan de kwaliteit van de gebruikerservaring. De informatie die we via cookies registreren, bestaat uit onder meer IP-adressen, het type browser en de bezochte pagina’s.',
      'Tevens monitoren we waar bezoekers de website voor het eerst bezoeken en vanaf welke pagina ze vertrekken. Deze informatie houden we anoniem bij en is niet gekoppeld aan andere persoonlijke informatie.',
    ],
  },
  {
    title: 'Gebruik van cookies',
    description: [
      'P2000Scanner.nl plaatst cookies bij bezoekers. Dat doen we om informatie te verzamelen over de pagina’s die gebruikers op onze website bezoeken, om bij te houden hoe vaak bezoekers terug komen en om te zien welke pagina’s het goed doen op de website.',
    ],
  },
  {
    title: 'Cookies uitschakelen',
    description: [
      'U kunt er voor kiezen om cookies (volledig) uit te schakelen. Dat doet u door gebruik te maken de mogelijkheden van uw browser. U vindt meer informatie over deze mogelijkheden op de website van de aanbieder van uw browser.',
    ],
  },
  {
    title: 'Cookies van derde partijen',
    description: [
      'Het is mogelijk dat derde partijen, zoals Google, op onze website adverteren of dat wij gebruik maken van een andere dienst. Daarvoor plaatsen deze derde partijen in sommige gevallen cookies. Deze cookies zijn niet door P2000Scanner.nl te beïnvloeden.',
    ],
  },
  {
    title: 'Onze adverteerders',
    description: [
      'Op P2000Scanner.nl adverteren meerdere partijen, die allemaal hun eigen cookies plaatsen.',
      'Deze adverteerders gebruiken technologie voor hun advertenties en de links in hun advertenties die direct door uw browser worden herkend. Ze gebruiken onder meer automatische herkenning van IP-adressen die rechtstreeks naar de adverteerder wordt gestuurd.',
      'Daarnaast worden cookies, javascript en eventueel webbeacons ingezet om te monitoren hoe effectief de campagnes van deze partijen zijn. P2000Scanner.nl heeft noch invloed noch controle over de cookies, het javascript en de webbeacons die onze adverteerders gebruiken.',
      'Al deze partijen hebben een eigen privacy policy en hanteren deze privacy policy voor het gebruik van hun website en de daaraan gekoppelde diensten. Meer informatie over hoe deze partijen omgaan met privacy vindt u op de websites van deze partijen',
    ],
  },
  {
    title: 'Privacy policy van adverteerders/derde partijen',
    description: [
      'Voor meer informatie over de privacy policy van onze adverteerders en derde partijen die verbonden zijn aan deze website, kun u terecht op de websites van deze respectievelijke partijen. P2000Scanner.nl kan geen invloed uitoefenen op deze cookies en de privacy policy van door derden geplaatste cookies. Deze cookies vallen buiten het bereik van de privacy policy van P2000Scanner.nl.',
    ],
  },
  {
    title: 'DART-cookie van Google',
    description: [
      'Het gebruik van een DART-cookie door Google, maakt het mogelijk dat er advertenties op onze website worden getoond. Daarnaast wordt deze cookie gebruikt voor het tonen van advertenties op andere websites.',
    ],
  },
];
