import { createSlice } from '@reduxjs/toolkit';

/**
 * The types structure which is used by the `App` state
 */
interface AppState {
  sidebarOpen: boolean;
}

/**
 * The initial values of the `App` state
 */
const initialState: AppState = {
  sidebarOpen: false,
};

/**
 * A slice which handles all the `App` state management logic
 */
const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    openSidebar: (state) => {
      state.sidebarOpen = true;
    },
    closeSidebar: (state) => {
      state.sidebarOpen = false;
    },
    toggleSidebar: (state) => {
      state.sidebarOpen = !state.sidebarOpen;
    },
  },
});

/**
 * The actions available for the "app" feature
 */
export const { openSidebar, closeSidebar, toggleSidebar } = appSlice.actions;

/**
 * An instance of the "app" reducer which handles logic for each action
 */
export const appReducer = appSlice.reducer;
