import { ReactNode, useEffect } from 'react';

import { setTheme } from './theme.slice';

import { useAppDispatch } from '~hooks';
import { ThemeType } from '~themes';

/**
 * The property types which are used by the `Theme` wrapper
 */
interface ThemeWrapperProps {
  children: ReactNode;
}

/**
 * A wrapper that provides the values, logic and data for the `Theme` feature
 *
 * @param props The standard properties which are always available
 * @param props.children The child element(s) which should be rendered within the wrapper
 *
 * @returns The `Theme` wrapper
 */
export function ThemeWrapper({ children }: ThemeWrapperProps): JSX.Element {
  const dispatch = useAppDispatch();

  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)',
  ).matches;

  /**
   * Loads a theme from localstorage and checks if it's valid
   * @returns If the loaded theme is valid
   */
  const isThemeValid = () => {
    const selectedTheme = localStorage.getItem('selected_theme');
    const isValid = selectedTheme === 'DARK' || selectedTheme === 'LIGHT';
    if (!isValid) {
      localStorage.removeItem('selected_theme');
    }
    return isValid;
  };

  /**
   * Handles the setting of the theme, if no theme is available load it from preferences
   */
  useEffect(() => {
    if (isThemeValid()) {
      dispatch(setTheme(localStorage.getItem('selected_theme') as ThemeType));
    } else {
      dispatch(setTheme(prefersDarkMode ? 'DARK' : 'LIGHT'));
    }
  }, []);

  return <>{children}</>;
}
