import { Box } from '@material-ui/core';

import { FilterToggler, Loader, MeldingList, Meta } from '~components';
import { FilterWrapper, MeldingWrapper } from '~features';
import { useAppSelector } from '~hooks';

/**
 * A page that displays "meldingen" and provides UI actions for them
 *
 * @param props The standard properties which are always available
 *
 * @returns The `MonitorPage` page
 */
export function MonitorPage() {
  const { meldingen } = useAppSelector((state) => state.melding);

  return (
    <>
      <Meta
        title="P2000Scanner | Monitor"
        description="De P2000Scanner Monitor is een grensverleggende applicatie voor het bijhouden van incidenten binnen heel Nederland. Het systeem van P2000Scanner staat bekend om haar snelheid, betrouwbaarheid en efficiëntie. Blijf op de hoogte, volg ons op social media!"
        image="https://p2000scanner.nl/static/images/cover.jpg"
        url="https://p2000scanner.nl"
        twitterName="@ScannerP2000"
        type="page"
      />
      <MeldingWrapper>
        <FilterWrapper>
          {meldingen.length > 0 ? (
            <Box p={2}>
              <MeldingList meldingen={meldingen} hasActions />
            </Box>
          ) : (
            <Loader />
          )}
          <FilterToggler />
        </FilterWrapper>
      </MeldingWrapper>
    </>
  );
}
