import { Helmet } from 'react-helmet';

/**
 * The property types which are used by the `BigNumberCard` component
 */
type MetaProps = {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  imageWidth?: string;
  imageHeight?: string;
  twitterName?: string;
  type?: string;
};

/**
 * The default property values which are used by the `CustomMap` component
 */
const defaultProps: Partial<MetaProps> = {
  title: 'P2000Scanner.nl',
  description: undefined,
  image: undefined,
  url: undefined,
  imageWidth: undefined,
  imageHeight: undefined,
  twitterName: undefined,
  type: undefined,
};

/**
 * A component that displays a card with a title and a big number
 *
 * @param props The standard properties which are always available
 * @param props.title The title of the page
 * @param props.description The description of the page
 * @param props.image The image url
 * @param props.url The page url
 * @param props.imageWidth The Width of the image
 * @param props.imageHeight The Height of the image
 * @param props.twitterName The Twitter Username
 * @param props.type The type of the post
 *
 * @returns The `Meta` component
 */
export function Meta({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
  twitterName,
  type,
}: MetaProps): JSX.Element {
  return (
    <Helmet>
      {title !== undefined && <title>{title}</title>}
      <meta property="og:locale" content="nl_NL" />
      {title !== undefined && <meta name="title" content={title} />}
      <meta property="og:site_name" content="P2000Scanner.nl" />
      {type !== undefined && <meta property="og:type" content={type} />}
      {title !== undefined && <meta property="og:title" content={title} />}
      {description !== undefined && (
        <meta name="description" content={description} />
      )}
      {description !== undefined && (
        <meta property="og:description" content={description} />
      )}
      {image !== undefined && <meta property="og:image" content={image} />}
      {image !== undefined && (
        <meta property="og:image:secure_url" content={image} />
      )}
      {url !== undefined && <meta property="og:url" content={url} />}
      {imageWidth !== undefined && (
        <meta property="og:image:width" content={imageWidth} />
      )}
      {imageHeight !== undefined && (
        <meta property="og:image:height" content={imageHeight} />
      )}
      {title !== undefined && <meta name="twitter:title" content={title} />}
      {description !== undefined && (
        <meta name="twitter:description" content={description} />
      )}
      {image !== undefined && <meta name="twitter:image" content={image} />}
      {twitterName !== undefined && (
        <meta name="twitter:site" content={twitterName} />
      )}
      {twitterName !== undefined && (
        <meta name="twitter:creator" content={twitterName} />
      )}
    </Helmet>
  );
}

/**
 * Assign the default properties to the `Meta` component
 */
Meta.defaultProps = defaultProps;
