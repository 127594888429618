import { IconButton, Tooltip } from '@material-ui/core';
import { HelpOutline as Icon } from '@material-ui/icons';

/**
 * The property types which are used by the `HelperTip` component
 */
interface HelperTipProps {
  text: string;
}

/**
 * A component that shows a HelperTip on hover
 *
 * @param props The standard properties which are always available
 * @param props.text The text to display when shown
 *
 * @returns The `HelperTip` component
 */
export function HelperTip({ text }: HelperTipProps) {
  return (
    <Tooltip
      title={text}
      enterTouchDelay={0}
      leaveTouchDelay={text.length * 50}
      placement="top"
    >
      <IconButton size="small">
        <Icon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}
