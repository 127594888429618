import { useRef } from 'react';

import axios, { AxiosInstance } from 'axios';

import { useVariable } from './useVariable';

import { useResponseInterceptorError } from '~config/interceptors';

/**
 * An instance of axios to communicate with the API
 */
const instance = axios.create({
  baseURL: useVariable('API_URI'),
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * Attach an interceptor to handle automatic response actions
 */
instance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(useResponseInterceptorError(error)),
);

/**
 * A hook that provides access to the back-end API
 *
 * @returns The `useApi` hook
 */
export function useApi(): AxiosInstance {
  /**
   * Stores the API in a reference
   */
  const { current: api } = useRef(instance);

  return api;
}
