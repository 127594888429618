import { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { ArrowBack, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import {
  EmailIcon,
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

import useStyles from './MeldingDetailsPage.style';

import {
  CustomMap,
  MeldingList,
  UnavailableItems,
  VehicleCard,
  Loader,
  Meta,
} from '~components';
import { useApi } from '~hooks';
import {
  arrayToList,
  getMeldingLocation,
  getLifelinerLocation,
  getMeldingTags,
  getTimeFull,
  isTimeLessThenHourFromNow,
} from '~utils';

/**
 * The property types which are used by the `MeldingDetailsPage` url parameters
 */
interface MeldingDetailsParams {
  id: string;
}

/**
 * A page that displays in-depth details about a "melding"
 *
 * @param props The standard properties which are always available
 *
 * @returns The `MeldingDetailsPage` page
 */
export function MeldingDetailsPage() {
  const classes = useStyles();
  const history = useHistory();
  const api = useApi();
  const { id } = useParams<MeldingDetailsParams>();
  const PAGE_URL = `https://p2000scanner.nl/melding/${id}`;

  const [melding, setMelding] = useState<Record<string, any> | undefined>();
  const [lifelinerLocation, setLifelinerLocation] = useState<
    GlobalLocation | undefined
  >();

  const isLifelinerMelding = !!melding?.lifeliner;
  const amountOfVoertuigen = melding?.voertuigen.length;
  const hasVoertuigen = amountOfVoertuigen > 0;
  const amountOfSubmeldingen = melding?.meldingen.length;
  const hasSubmeldingen = amountOfSubmeldingen > 0;
  const meldingLocatie = melding ? getMeldingLocation(melding) : undefined;

  const getImageUrl = (roepnummer?: string) => {
    if (roepnummer === undefined) {
      return `${process.env.REACT_APP_PHOTO_API}/${id}/null/image.jpg`;
    }
    return `${process.env.REACT_APP_PHOTO_API}/${id}/${roepnummer}/image.jpg`;
  };
  /**
   * Fetches the melding data and sets it to the state
   */
  useEffect(() => {
    const fetch = async () => {
      const { data } = await api.get(`/meldingen/${id}`);
      setMelding(data ?? undefined);
    };

    fetch();
  }, [id]);

  useEffect(() => {
    const fetchLifelinerLocation = async () => {
      const { data } = await api.get(`/aircraft/${melding?.lifeliner}`);
      if (data.length !== 0) {
        setLifelinerLocation(data ? getLifelinerLocation(data) : undefined);
      }
    };

    fetchLifelinerLocation();
  }, [isLifelinerMelding, melding]);

  return melding ? (
    <>
      <Meta
        title={melding.melding}
        description={melding.titel || melding.melding}
        image={getImageUrl()}
        url={PAGE_URL}
        imageWidth="1280"
        imageHeight="720"
        twitterName="@ScannerP2000"
        type="article"
      />
      <Container className={classes.root}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <IconButton onClick={() => history.goBack()}>
            <ArrowBack />
          </IconButton>
          <Typography component="h1" variant="h5" className={classes.title}>
            {melding.titel || melding.melding}
          </Typography>
        </Box>
        <Paper className={classes.view} variant="elevation">
          <Grid container alignItems="stretch" spacing={2}>
            <Grid item xs={12} md={6} xl={7}>
              <Paper variant="outlined" className={classes.map}>
                <CustomMap location={meldingLocatie} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} xl={5}>
              <Paper variant="outlined" className={classes.paper}>
                <Typography variant="subtitle2">Datum en tijd</Typography>
                <Typography variant="body1">
                  {getTimeFull(melding.tijd)}
                </Typography>
                <Divider />
                <Typography variant="subtitle2">Melding</Typography>
                <Typography variant="body1">{melding.melding}</Typography>
                <Divider />
                <Typography variant="subtitle2">Prioriteit</Typography>
                <Typography variant="body1">{melding.prio}</Typography>
                <Divider />
                <Typography variant="subtitle2">Capcodes overzicht</Typography>
                <Typography variant="body1">
                  {arrayToList<string>(melding.capcodes)}
                </Typography>
                <Divider />
                <Typography variant="subtitle2">Tags</Typography>
                <Box className={classes.tags}>
                  {getMeldingTags(melding, 'cruciaal').map((tag) => (
                    <Chip
                      key={tag.type}
                      color={tag.enabled ? 'secondary' : 'default'}
                      label={tag.type}
                      disabled={!tag.enabled}
                      style={{
                        textDecoration: tag.enabled ? 'none' : 'line-through',
                      }}
                    />
                  ))}
                </Box>
                <Divider />
                <Typography variant="subtitle2">Delen:</Typography>
                <Box className={classes.share}>
                  <FacebookShareButton
                    url={PAGE_URL}
                    quote={melding.titel || melding.melding}
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={PAGE_URL}
                    title={melding.titel || melding.melding}
                    via="ScannerP2000"
                    hashtags={['P2000Scanner']}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={PAGE_URL}
                    title={melding.titel || melding.melding}
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <TelegramShareButton
                    url={PAGE_URL}
                    title={melding.titel || melding.melding}
                  >
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>
                  <EmailShareButton
                    url={PAGE_URL}
                    subject={melding.titel || melding.melding}
                  >
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                  <LinkedinShareButton
                    url={PAGE_URL}
                    title={melding.titel || melding.melding}
                    source="https://p2000scanner.nl"
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.view}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Accordion
                variant="outlined"
                expanded={hasVoertuigen ? undefined : true}
                disabled={!hasVoertuigen}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon fontSize="large" />}
                >
                  <Typography variant="h5">
                    Voertuigen ({amountOfVoertuigen})
                  </Typography>
                </AccordionSummary>
                <Divider variant="middle" />
                <AccordionDetails>
                  {hasVoertuigen ? (
                    <Grid container spacing={2}>
                      {melding.voertuigen.map(
                        (voertuig: Record<string, any>) => (
                          <Grid
                            key={voertuig._id}
                            item
                            xs={12}
                            sm={6}
                            lg={4}
                            className={classes.cardWrapper}
                          >
                            <VehicleCard
                              vehicle={voertuig}
                              priority={melding.prio}
                            />
                          </Grid>
                        ),
                      )}
                    </Grid>
                  ) : (
                    <UnavailableItems message="Er zijn geen voertuigen gevonden bij deze melding" />
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion
                variant="outlined"
                expanded={hasSubmeldingen ? undefined : true}
                disabled={!hasSubmeldingen}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon fontSize="large" />}
                >
                  <Typography variant="h5">
                    Bijbehorende meldingen ({amountOfSubmeldingen})
                  </Typography>
                </AccordionSummary>
                <Divider variant="middle" />
                <AccordionDetails>
                  {hasSubmeldingen ? (
                    <Grid container direction="column">
                      <MeldingList
                        meldingen={melding.meldingen.map(
                          (m: Record<string, any>) => ({
                            ...m,
                            melding: melding.melding,
                            tijd: melding.tijd,
                          }),
                        )}
                      />
                    </Grid>
                  ) : (
                    <UnavailableItems message="Er zijn geen gerelateerde meldingen gevonden bij deze melding" />
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
            {isLifelinerMelding && isTimeLessThenHourFromNow(melding.tijd) && (
              <Grid item xs={12}>
                <Accordion
                  variant="outlined"
                  expanded={isLifelinerMelding ? undefined : true}
                  disabled={!isLifelinerMelding}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                  >
                    <Typography variant="h5">Lifeliner locatie</Typography>
                  </AccordionSummary>
                  <Divider variant="middle" />
                  <AccordionDetails style={{ height: 400 }}>
                    <CustomMap location={lifelinerLocation} />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}
            <Grid item xs={12}>
              <Accordion variant="outlined">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon fontSize="large" />}
                >
                  <Typography variant="h5">
                    Download melding afbeelding
                  </Typography>
                </AccordionSummary>
                <Divider variant="middle" />
                <AccordionDetails>
                  <Accordion variant="outlined">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon fontSize="large" />}
                    >
                      <Typography variant="h5">Standaard</Typography>
                    </AccordionSummary>
                    <Divider variant="middle" />
                    <AccordionDetails>
                      <img
                        className={classes.media}
                        src={getImageUrl()}
                        alt={melding.titel || melding.melding}
                      />
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
                {hasVoertuigen && (
                  <>
                    {melding.voertuigen.map((voertuig: Record<string, any>) => (
                      <AccordionDetails>
                        <Accordion variant="outlined">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon fontSize="large" />}
                          >
                            <Typography variant="h5">
                              {voertuig.roepnummer || 'Onbekend roepnummer'}
                            </Typography>
                          </AccordionSummary>
                          <Divider variant="middle" />
                          <AccordionDetails>
                            <img
                              className={classes.media}
                              src={getImageUrl(voertuig.roepnummer)}
                              alt={melding.titel || melding.melding}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </AccordionDetails>
                    ))}
                  </>
                )}
              </Accordion>
            </Grid>
          </Grid>
        </Paper>
        {melding !== undefined && (
          <Box textAlign="center">
            <Typography variant="overline">
              Bevat deze melding een fout? Klik{' '}
              <a
                href={`mailto:info@p2000scanner.nl?subject=Fout%20bij%20melding%20${melding._id}&body=Beschrijf de fout:\n`}
              >
                hier
              </a>{' '}
              om het te melden.
            </Typography>
          </Box>
        )}
      </Container>
    </>
  ) : (
    <Loader />
  );
}
