import {
  blue,
  green,
  grey,
  orange,
  pink,
  red,
  yellow,
} from '@material-ui/core/colors';

/**
 * The type of themes which are accepted
 */
type ThemeType = 'dark' | 'light';

/**
 * The type used by colors
 */
type Color = { [key: string]: string };

/**
 * Makes a color set
 * @param color The color to use
 * @param type The type of the theme
 * @param offset
 * @returns
 */
export const makeColorSet = (color: Color, type: ThemeType, offset = 0) => {
  const isDark = type === 'dark';
  const hue = isDark ? 600 - offset : 700 - offset;
  const titleColor = `${color[hue + (isDark ? -100 : 200)]} !important`;
  const borderColor = `${color[hue]} !important`;
  return {
    '&--title': {
      color: titleColor,
    },
    '&--border': {
      borderLeftColor: borderColor,
    },
  };
};

/**
 * The global stylesheet
 * @param type The type of the theme
 * @returns The global stylesheet based on the theme type
 */
export const GLOBAL_STYLES = (type: ThemeType) => ({
  '.AMBU': makeColorSet(yellow, type),
  '.BRAN': makeColorSet(red, type),
  '.POLI': makeColorSet(blue, type),
  '.KNRM': makeColorSet(orange, type),
  '.BRUG': makeColorSet(green, type),
  '.ONBE': makeColorSet(grey, type, type === 'dark' ? 300 : 0),
  '.HELI': makeColorSet(pink, type, 400),
});
