import { Box, useTheme } from '@material-ui/core';
import { GridLoader as LoadingAnimation } from 'react-spinners';

import useStyles from './Loader.style';

/**
 * A component that displays a loading animation
 *
 * @param props The standard properties which are always available
 *
 * @returns The `Loader` component
 */
export function Loader(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box
      className={classes.root}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <LoadingAnimation color={theme.palette.secondary.main} />
    </Box>
  );
}
