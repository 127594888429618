import dayjs from 'dayjs';

/**
 * Returns the full date of a timestamp
 * @param time The time to extract the date from
 * @returns The date of the timestamp in full
 */
export const getDate = (time: string) => {
  const date = dayjs(time);
  return date.format('DD MMMM YYYY');
};

/**
 * Returns the short version of a date
 * @param time The time to format as a short version
 * @returns The short formatted version of the time
 */
export const getTimeShort = (time: string) => {
  const date = dayjs(time);
  return date.format('HH:mm:ss DD/MM/YYYY');
};

/**
 * Returns the formatted time with custom locale
 * @param time The time to format as a string
 * @returns The formatted time
 */
export const getTimeFull = (time: string) => {
  const date = dayjs(time);
  return date.format('D MMMM YYYY [om] HH:mm:ss');
};

/**
 * Returns if time is less then one hour from now
 * @param time The time to format as a string
 * @returns true of false
 */
export const isTimeLessThenHourFromNow = (time: string) => {
  const date1 = dayjs(time);
  const date2 = dayjs();

  let hours = date2.diff(date1, 'hours');
  const days = Math.floor(hours / 24);
  hours -= days * 24;

  if (hours >= 1) {
    return false;
  }
  return true;
};
