import { fade, useTheme } from '@material-ui/core';
import {
  ResponsiveContainer,
  Label,
  Area,
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';

/**
 * The property types which are used by the `CustomChart` component
 */
interface CustomChartProps {
  data: Record<string | number, string | number>[];
  xKey: string | number;
  yKey: string | number;
  yLabel: string;
}

/**
 * A component that displays and Area chart with given data
 *
 * @param props The standard properties which are always available
 * @param props.data The data to make a chart from
 * @param props.xKey The key to use for the X-axis
 * @param props.yKey The key to use for the Y-axis
 * @param props.yLabel The label to display on the Y-axis
 *
 * @returns The `CustomChart` component
 */
export function CustomChart({
  data,
  xKey,
  yKey,
  yLabel,
}: CustomChartProps): JSX.Element {
  const theme = useTheme();

  return (
    <ResponsiveContainer>
      <AreaChart data={data}>
        <defs>
          <linearGradient id="colorAxisY" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={theme.palette.primary.main}
              stopOpacity={0.9}
            />
            <stop
              offset="95%"
              stopColor={theme.palette.primary.main}
              stopOpacity={0.2}
            />
          </linearGradient>
        </defs>
        <XAxis dataKey={xKey} stroke={theme.palette.text.secondary} />
        <YAxis dataKey={yKey} stroke={theme.palette.text.secondary}>
          <Label
            angle={270}
            position="insideLeft"
            style={{
              textAnchor: 'middle',
              fill: theme.palette.text.primary,
            }}
          >
            {yLabel}
          </Label>
        </YAxis>
        <Tooltip
          labelStyle={{ color: 'black', fontWeight: 'bold' }}
          itemStyle={{ color: 'black' }}
        />
        <Area
          type="monotone"
          dot
          dataKey={yKey}
          stroke={fade(theme.palette.primary.main, 0.5)}
          fillOpacity={1}
          fill="url(#colorAxisY)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
