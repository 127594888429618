import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * The type that resembles a list of "meldingen"
 */
type MeldingList = Record<string, unknown>[];

/**
 * The types structure which is used by the `Melding` state
 */
interface MeldingState {
  meldingen: MeldingList;
}

/**
 * The initial values of the `Melding` state
 */
const initialState: MeldingState = {
  meldingen: [],
};

/**
 * A slice which handles all the `Melding` state management logic
 */
const meldingSlice = createSlice({
  name: 'melding',
  initialState,
  reducers: {
    setMeldingen: (state, action: PayloadAction<MeldingList>) => {
      state.meldingen = action.payload;
    },
  },
});

/**
 * The actions available for the "melding" feature
 */
export const { setMeldingen } = meldingSlice.actions;

/**
 * An instance of the "melding" reducer which handles logic for each action
 */
export const meldingReducer = meldingSlice.reducer;
