import { Drawer, Hidden, SwipeableDrawer } from '@material-ui/core';

import useStyles from './Sidebar.styles';
import { SidebarContent } from './SidebarContent/SidebarContent';

import { closeSidebar, openSidebar } from '~features';
import { useAppDispatch, useAppSelector } from '~hooks';

/**
 * A component that handles user navigation at the side of the screen
 *
 * @param props The standard properties which are always available
 *
 * @returns The `Sidebar` component
 */
export function Sidebar(): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { sidebarOpen } = useAppSelector((state) => state.app);

  const handleOpen = () => dispatch(openSidebar());
  const handleClose = () => dispatch(closeSidebar());

  return (
    <>
      <Hidden mdUp>
        <SwipeableDrawer
          variant="temporary"
          anchor="left"
          classes={{ paper: classes.drawerPaper }}
          open={sidebarOpen}
          onOpen={handleOpen}
          onClose={handleClose}
        >
          <SidebarContent />
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          open
          variant="permanent"
          classes={{ paper: classes.drawerPaper }}
        >
          <SidebarContent />
        </Drawer>
      </Hidden>
    </>
  );
}
