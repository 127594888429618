import { makeStyles } from '@material-ui/core';

/**
 * The styles which are used by the `CustomMap` component
 */
export default makeStyles((theme) => ({
  map: {
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
    '& [class^="leaflet-control-zoom-"]': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
  },
  noLocation: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    fontStyle: 'italic',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    '& *': {
      opacity: 0.5,
    },
  },
  icon: {
    fontSize: '5em',
  },
  text: {
    marginTop: theme.spacing(2),
  },
}));
