import { makeStyles } from '@material-ui/core';

/**
 * The styles which are used by the `UnavailableItems` component
 */
export default makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
    fontStyle: 'italic',
    opacity: 0.6,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));
