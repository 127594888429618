import { useEffect, useState } from 'react';

import { Box, Divider, Paper, Typography } from '@material-ui/core';
import {
  HourglassEmptyOutlined as UnstartedIcon,
  CheckOutlined as CompletedIcon,
  WorkOutlined as ProgressIcon,
} from '@material-ui/icons';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import dayjs from 'dayjs';

import useStyles from './RoadmapPage.style';

import { useApi } from '~hooks';

/**
 * A page that displays roadmap information about the application
 *
 * @param props The standard properties which are always available
 *
 * @returns The `RoadmapPage` page
 */
export function RoadmapPage(): JSX.Element {
  const classes = useStyles();
  const api = useApi();

  const [roadmap, setRoadmaps] = useState<Record<string, any>[]>();

  /**
   * Gets phase of timeline item
   * @param {string} startDate
   * @param {string} endDate
   * @returns Phases:
   *    0, Not started yet |
   *    1, In progress |
   *    2, Completed
   */

  const getPhase = (startDate: string, eindDate: string) => {
    if (dayjs().isBefore(startDate)) {
      return { phase: 0, icon: UnstartedIcon, color: classes.backgroundGrey };
    }
    if (dayjs().isAfter(startDate) && dayjs().isBefore(eindDate)) {
      return { phase: 1, icon: ProgressIcon, color: classes.backgroundOrange };
    }
    if (dayjs().isAfter(eindDate)) {
      return { phase: 2, icon: CompletedIcon, color: classes.backgroundGreen };
    }
    return { phase: 0, icon: ProgressIcon, color: classes.backgroundBlack };
  };

  /**
   * Fetches the update data and sets it to the state
   */
  useEffect(() => {
    const fetch = async () => {
      const { data } = await api.get(`/roadmap`);
      setRoadmaps(data ?? undefined);
    };
    fetch();
  }, []);

  return (
    <Box p={2}>
      <Typography variant="h4" className={classes.title}>
        Roadmap
      </Typography>
      <Typography className={classes.subtitle}>
        Op deze pagina kun je zien wat er gemaakt gaat worden in een bepaalde
        periode.
      </Typography>
      <Box pt={2}>
        <Divider />
      </Box>
      <Box
        className={classes.root}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="column"
      >
        <Timeline className={classes.timeline}>
          {roadmap?.map((roadmapItem) => {
            const phase = getPhase(roadmapItem.startDate, roadmapItem.eindDate);
            const finishDate = dayjs(roadmapItem.eindDate).format(
              'MMMM - YYYY',
            );
            return (
              <TimelineItem key={roadmapItem._id}>
                <TimelineOppositeContent className={classes.sideContent}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="space-between"
                    flexDirection="column"
                  >
                    {finishDate.slice(0, 1).toUpperCase() +
                      finishDate.slice(1, finishDate.length)}
                  </Box>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    className={phase.color}
                    variant={phase.phase ? 'default' : 'outlined'}
                  />
                  {phase.phase === 2 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Paper className={classes.paper}>
                    <Typography className={classes.updateTitle} variant="body1">
                      {roadmapItem.titel}
                    </Typography>
                    {roadmapItem.omschrijving}
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </Box>
    </Box>
  );
}
