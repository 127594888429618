import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormGroup,
  Grid,
  GridSize,
  Typography,
} from '@material-ui/core';

import { FilterSelector } from '../FilterSelector/FilterSelector';
import { HelperTip } from '../HelperTip/HelperTip';

import useStyles from './FilterItem.style';

import { closeFilterMenu } from '~features';
import { useAppDispatch, useAppSelector } from '~hooks';
import { FilterDetails, FilterItem as FilterItemType } from '~models/api';
import { hasNestedFilterItems } from '~utils';

/**
 * The property types which are used by the `FilterItem` component
 */
interface FilterItemProps {
  details: FilterDetails;
}

/**
 * A component that displays the details about a filter item with built-in logic and selection
 *
 * @param props The standard properties which are always available
 * @param props.details The details about the filter item
 *
 * @returns The `FilterItem` component
 */
export function FilterItem({ details }: FilterItemProps): JSX.Element {
  const classes = useStyles();
  const { filterMenusOpen } = useAppSelector((state) => state.filter);
  const dispatch = useAppDispatch();

  /**
   * Checks if the current filter item is open
   */
  const isCurrentItemOpen = filterMenusOpen[details.type];

  /**
   * Handles the closing of the current filter item
   */
  const handleClose = () => dispatch(closeFilterMenu(details.type));

  return (
    <Dialog open={isCurrentItemOpen} onClose={handleClose}>
      <DialogTitle>{details.title}</DialogTitle>
      <Divider />
      <Grid container className={classes.content}>
        {details.filters.map((filter: FilterItemType) => (
          <Grid
            item
            key={details.title + filter.items.length}
            xs={12}
            className={classes.subject}
          >
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.subtitle}>
                {filter.title}
                {filter.description && <HelperTip text={filter.description} />}
              </Typography>
            </Grid>
            <FormGroup>
              {hasNestedFilterItems(filter) ? (
                <Grid container>
                  {filter.items.map((filterItem: any) => {
                    /**
                     * Make equal columns based on the nested array
                     * (12 is the maximum columms for `@material-ui`)
                     */
                    const gridSize = Math.floor(
                      12 / filter.items.length,
                    ) as GridSize;

                    return (
                      <Grid item key={filterItem.order} xs={12} md={gridSize}>
                        <FilterSelector
                          key={filterItem.order}
                          type={details.type}
                          filterItems={filterItem.items}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <FilterSelector
                  type={details.type}
                  filterItems={filter.items}
                />
              )}
            </FormGroup>
          </Grid>
        ))}
      </Grid>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose}>SLUITEN</Button>
      </DialogActions>
    </Dialog>
  );
}
