import { DEFAULT_IMAGE_PATH, VEHICLE_IMAGE_TYPES } from '~common/variables';

/**
 * Formats the vehicle name and adds it's abbreviation if available
 * @param name The name of the vehicle
 * @param abbreviation The abbreviation name of the vehicle
 * @returns The formatted vehicle name
 */
export const getVehicleName = (name: string, abbreviation?: string) => {
  let text = name;
  if (abbreviation) {
    text += ` (${abbreviation})`;
  }
  return text;
};

/**
 * Select the proper image and it's author from a vehicle
 * @param vehicle The data of the vehicle to use
 * @param priority The priority of the "melding" to determine which image to use
 * @returns An object with the image url and it's author
 */
export const getVehicleImage = (
  vehicle: Record<string, any>,
  priority: string,
): ExternalImage => {
  let priorityAmount = parseInt(priority.replace(/[\sA-Za-z]/, ''), 10) - 1;
  if (
    Number.isNaN(priorityAmount) ||
    priorityAmount > 2 ||
    priorityAmount < 0
  ) {
    priorityAmount = 2;
  }
  const imageType = VEHICLE_IMAGE_TYPES[priorityAmount];

  let vehicleImage: ExternalImage = {
    path: vehicle[`foto${imageType}`],
    author: vehicle[`fotograaf${imageType}`],
  };

  if (vehicleImage.path === null || vehicleImage.path === '') {
    const imageTypes = VEHICLE_IMAGE_TYPES.map((type) => ({
      pathKey: `foto${type}`,
      authorKey: `fotograaf${type}`,
    }));

    const usableImages: ExternalImage[] = imageTypes
      .map((type) => ({
        path: vehicle[type.pathKey],
        author: vehicle[type.authorKey],
      }))
      .filter((image) => !!image.path && !!image.author);

    vehicleImage =
      usableImages[Math.floor(usableImages.length * Math.random())];
  }

  return {
    path: vehicleImage?.path
      ? process.env.REACT_APP_PHOTO_URI + vehicleImage.path
      : DEFAULT_IMAGE_PATH,
    author: vehicleImage?.path
      ? vehicleImage.author || 'Onbekende Fotograaf'
      : '',
  };
};
