import { ReactNode } from 'react';

import useStyles from './Layout.style';

import { Navbar, Sidebar } from '~components';

/**
 * The property types which are used by the `Layout` container
 */
interface LayoutProps {
  children: ReactNode;
}

/**
 * A container that handles the global application layout
 *
 * @param props The standard properties which are always available
 * @param props.children The child element(s) which should be rendered within the container
 *
 * @returns The `Layout` container
 */
export function Layout({ children }: LayoutProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Sidebar />
      <Navbar />
      <main className={classes.content}>{children}</main>
    </>
  );
}
