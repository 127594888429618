import { Melding } from '~components/Melding/Melding';

/**
 * The property types which are used by the `MeldingList` component
 */
type MeldingListProps = {
  meldingen: Record<string, any>[];
  hasActions?: boolean;
};

/**
 * The default property values which are used by the `MeldingList` component
 */
const defaultProps: Partial<MeldingListProps> = {
  hasActions: false,
};

/**
 * A component that renders a list of "meldingen"
 *
 * @param props The standard properties which are always available
 * @param props.meldingen The data to iterate through and display a "melding" for each record
 *
 * @returns The `MeldingList` component
 */
export function MeldingList({
  meldingen,
  hasActions,
}: MeldingListProps): JSX.Element {
  return (
    <>
      {meldingen.map((melding) => (
        <Melding key={melding._id} melding={melding} hasActions={hasActions} />
      ))}
    </>
  );
}

/**
 * Assign the default properties to the `MeldingList` component
 */
MeldingList.defaultProps = defaultProps;
