import {
  Search as FilterIconClosed,
  Close as FilterIconOpened,
} from '@material-ui/icons';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab';

import { FilterItem } from '../FilterItem/FilterItem';

import useStyles from './FilterToggler.style';

import { getFilterIcon } from '~common/icons';
import { closeFilter, openFilter, openFilterMenu } from '~features';
import { useAppDispatch, useAppSelector } from '~hooks';
import { FilterType } from '~models/stream';

/**
 * The property types which are used by the `FilterToggler` component
 */
interface FilterTogglerProps {
  disabled?: boolean;
}

/**
 * The default property values which are used by the `FilterToggler` component
 */
const defaultProps: Partial<FilterTogglerProps> = {
  disabled: false,
};

/**
 * A component that handles the opening and closing of the filter menu
 *
 * @param props The standard properties which are always available
 * @param props.disabled If the filter toggler should be disabled (hidden)
 *
 * @returns The `FilterToggler` component
 */
export function FilterToggler({ disabled }: FilterTogglerProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { isOpen, filters } = useAppSelector((state) => state.filter);

  /**
   * Handles the toggling of the filter options menu
   */
  const handleToggle = () => dispatch(isOpen ? closeFilter() : openFilter());

  /**
   * Handles the opening of a filter menu
   */
  const handleFilterMenuOpen = (type: string) => dispatch(openFilterMenu(type));

  return (
    <>
      {filters.map((filter) => (
        <FilterItem key={filter.type} details={filter} />
      ))}
      <SpeedDial
        hidden={disabled}
        className={classes.root}
        ariaLabel="melding filters aanpassen"
        icon={
          <SpeedDialIcon
            openIcon={<FilterIconOpened />}
            icon={<FilterIconClosed />}
          />
        }
        FabProps={{
          disabled: filters.length < 1,
        }}
        open={isOpen}
        onClick={handleToggle}
      >
        {filters.map((filter) => {
          const FilterIcon = getFilterIcon(filter.type as FilterType).main;
          return (
            <SpeedDialAction
              key={filter.type}
              aria-label={filter.title}
              icon={<FilterIcon />}
              tooltipTitle={filter.label}
              tooltipOpen
              onClick={() => handleFilterMenuOpen(filter.type)}
            />
          );
        })}
      </SpeedDial>
    </>
  );
}

/**
 * Assign the default properties to the `FilterToggler` component
 */
FilterToggler.defaultProps = defaultProps;
