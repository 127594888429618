import { Typography } from '@material-ui/core';

import useStyles from './Capcode.style';

import { CapcodeDetails } from '~models/api';
import { getCapcodeDescription } from '~utils/capcode.util';

/**
 * The property types which are used by the `Capcode` component
 */
type CapcodeProps = {
  capcode: CapcodeDetails;
};

/**
 * A component that displays details about a "capcode" as text
 *
 * @param props The standard properties which are always available
 * @param props.capcode The data which should be used to display "capcode" information
 *
 * @returns The `Capcode` component
 */
export function Capcode({ capcode }: CapcodeProps): JSX.Element {
  const classes = useStyles();

  return (
    <Typography variant="body1" className={classes.root}>
      <span className={classes.capcode}>{capcode.capcode}</span>
      <span>{capcode?.discipline?.afkorting}</span>
      <span>{capcode?.gpkr}</span>
      <span className={classes.description}>
        {getCapcodeDescription(capcode.omschrijving)}
      </span>
    </Typography>
  );
}
