import { Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { Launch as MeldingInfoIcon } from '@material-ui/icons';
import clsx from 'classnames';
import { useHistory } from 'react-router-dom';

import { Capcode } from '../Capcode/Capcode';

import useStyles from './Melding.style';

import { CapcodeDetails } from '~models/api';
import { getTimeShort } from '~utils';

/**
 * The property types which are used by the `Melding` component
 */
interface MeldingProps {
  melding: Record<string, any>;
  hasActions?: boolean;
}

/**
 * The default property values which are used by the `Melding` component
 */
const defaultProps: Partial<MeldingProps> = {
  hasActions: true,
};

/**
 * A component that displays details about a "melding"
 *
 * @param props The standard properties which are always available
 * @param props.melding The data which should be used to display "melding" information
 * @param props.hasActions If the "melding" has actions which the user can interact with
 *
 * @returns The `Melding` component
 */
export function Melding({ melding, hasActions }: MeldingProps): JSX.Element {
  const classes = useStyles();
  const history = useHistory();

  /**
   * Gets the colored classname for the discipline
   * @param variant The variant to use (`border | text`)
   * @returns The colored classname to use
   */
  const getColorClassName = (variant: string): string =>
    `${melding?.discipline?.benaming.toUpperCase()}--${variant}`;

  /**
   * An element which displays the "melding" title and it's "capcodes"
   * @returns The "melding" information with "capcodes"
   */
  const MeldingInfo = (): JSX.Element => (
    <>
      <Typography
        variant="h6"
        className={clsx(classes.titel, getColorClassName('title'))}
      >
        {melding.discipline.benaming} {melding.melding}
      </Typography>
      {melding.capcodes.map((capcode: CapcodeDetails) => (
        <Capcode key={capcode.capcode} capcode={capcode} />
      ))}
    </>
  );

  return (
    <Paper
      variant="outlined"
      className={clsx(classes.root, getColorClassName('border'))}
      key={melding._id}
    >
      {hasActions ? (
        <Grid container justify="space-between" direction="row">
          <Grid item xs={11}>
            <MeldingInfo />
          </Grid>
          <Grid item xs={1}>
            <Grid container justify="flex-end" alignItems="flex-start">
              <IconButton
                onClick={() =>
                  history.push(`/melding/${melding.hoofdmeldingId}`)
                }
              >
                <MeldingInfoIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <MeldingInfo />
      )}
      <Grid
        container
        className={classes.details}
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={6} sm={4}>
          <Typography variant="overline" component="span">
            {melding.discipline.naam}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Typography variant="overline" component="span">
            {melding.regio?.naam || 'Onbekende regio'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="overline" component="span" noWrap>
            {getTimeShort(melding.tijd)}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

/**
 * Assign the default properties to the `Melding` component
 */
Melding.defaultProps = defaultProps;
