/**
 * The width of the application sidebar, defined in pixels
 */
export const SIDEBAR_WIDTH: Readonly<number> = 240;

/**
 * The aspect ratio used by the logo of the application
 */
export const LOGO_ASPECT_RATIO: Readonly<number> = 1405 / 619;

/**
 * The type of vehicle images to use.
 *
 * **The order is important! Sorted from most important to less important**
 */
export const VEHICLE_IMAGE_TYPES = [
  'Spoed',
  'ZonderSpoed',
  'Standaard',
] as const;

/**
 * The default image path to use as a placeholder
 */
export const DEFAULT_IMAGE_PATH =
  `${process.env.PUBLIC_URL}/static/images/placeholder.jpg` as const;
