import { isEqual } from 'lodash';

/**
 * A function that turns a single-level array to a list of strings with comma's
 * @param items An array of items to turn into a list
 * @returns The stringified array list with comma's
 */
export const arrayToList = <T = string | number | boolean>(items: T[]) => {
  const formatted = items.map((item) => ` ${item}`);
  return formatted.toString();
};

/**
 * Makes an object with assigned values
 * @param object The object to make an object from
 * @param key The key to select
 * @param value The value to assign to each key
 * @returns The filled object
 */
export const makeObjectWithValues = (
  object: Record<string, any>,
  key: any,
  value: any,
) =>
  Object.keys(object).reduce(
    (prev, curr) => ({ ...prev, [object[curr][key]]: value }),
    {},
  );

/**
 * Checks if object with arrays is equal
 * @param current The current object to check
 * @param previous The previous object to compare with the current object
 * @returns If the object arrays are equal
 */
export const isObjectWithArraysEqual = (
  current: Record<string, string[]>,
  previous: Record<string, string[]>,
): boolean =>
  Object.keys(current).every((key) => isEqual(current[key], previous[key]));
