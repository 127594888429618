import { ReactNode, useEffect, useState } from 'react';

import { gzip } from 'pako';

import { setFilterItems } from './filter.slice';

import { setMeldingen } from '~features/melding';
import { useApi, useAppDispatch, useAppSelector, useStream } from '~hooks';
import { isObjectWithArraysEqual } from '~utils';

/**
 * The property types which are used by the `Filter` wrapper
 */
interface FilterWrapperProps {
  children: ReactNode;
}

/**
 * A wrapper that provides the values, logic and data for the `Filter` feature
 *
 * @param props The standard properties which are always available
 * @param props.children The child element(s) which should be rendered within the wrapper
 *
 * @returns The `Filter` wrapper
 */
export function FilterWrapper({ children }: FilterWrapperProps): JSX.Element {
  const api = useApi();
  const { isConnected, stream } = useStream();
  const { filtersApplied, filters, filterMenusOpen } = useAppSelector(
    (state) => state.filter,
  );
  const dispatch = useAppDispatch();
  const [previousFilters, setPreviousFilters] = useState({});

  /**
   * Checks if all filter items are closed to prevent filter spamming
   */
  const areFilterItemsClosed = Object.values(filterMenusOpen).every(
    (value) => value === false,
  );

  /**
   * Request the filter types data from the remote
   */
  useEffect(() => {
    if (filters.length < 1) {
      const fetch = async () => {
        const { data } = await api.get('/filters');
        dispatch(setFilterItems(data));
      };
      fetch();
    }
  }, []);

  /**
   * Handles the filtering to the stream
   */
  useEffect(() => {
    if (!isConnected) {
      return;
    }
    if (areFilterItemsClosed) {
      const equal = isObjectWithArraysEqual(filtersApplied, previousFilters);
      if (!equal) {
        setPreviousFilters(filtersApplied);
        dispatch(setMeldingen([]));
        stream.emit(
          '@@connector/meldingen/filter',
          gzip(JSON.stringify(filtersApplied), {
            to: 'string',
          }),
        );
      }
    }
  }, [areFilterItemsClosed, filtersApplied]);

  return <>{children}</>;
}
