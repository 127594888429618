/* eslint-disable no-unused-vars */
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { ungzip } from 'pako';

import sound from '../../assets/audio/sound.mp3';

import { setMeldingen } from './melding.slice';

import { store } from 'store';
import { useAppDispatch, useAppSelector, useStream } from '~hooks';

/**
 * The property types which are used by the `Melding` wrapper
 */
interface MeldingWrapperProps {
  children: ReactNode;
}

/**
 * A wrapper that provides the values, logic and data for the `Melding` feature
 *
 * @param props The standard properties which are always available
 * @param props.children The child element(s) which should be rendered within the wrapper
 *
 * @returns The `Melding` wrapper
 */
export function MeldingWrapper({ children }: MeldingWrapperProps): JSX.Element {
  const { stream } = useStream();
  const dispatch = useAppDispatch();
  const { notificationSound: notificationEnabled } = useAppSelector(
    (state) => state.notificationSound,
  );

  const [meldingId, setMeldingId] = useState<string>();
  const [oldMeldingId, setOldMeldingId] = useState<string>();
  const [initialized, setInitialized] = useState(false);

  const playAudio = () => {
    new Audio(sound).play();
  };

  /**
   * Parses meldingen and sets them to the state
   * @param data The gzipped data for meldingen to set
   */
  const meldingHandler = (data: Uint8Array) => {
    const parsed = JSON.parse(ungzip(data, { to: 'string' }));

    setMeldingId(parsed[0]._id);

    dispatch(setMeldingen(parsed));
  };

  useEffect(() => {
    if (oldMeldingId !== meldingId) {
      if (!initialized) {
        setInitialized(true);
      }
      if (notificationEnabled === 'ON' && !!initialized) {
        playAudio();
      }
      setMeldingId(meldingId);
      setOldMeldingId(meldingId);
    }
  }, [meldingId, oldMeldingId, notificationEnabled]);

  /**
   * Change "meldingen" data whenever the stream emits an update
   */
  useEffect(() => {
    stream.on('@@stream/meldingen', (data) => meldingHandler(data));

    return () => {
      stream.off('@@stream/meldingen', meldingHandler);
    };
  }, []);

  return <>{children}</>;
}
