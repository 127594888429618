import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * The type that resembles a list of "meldingen"
 */
type LiveMeldingList = Record<string, unknown>[];

/**
 * The types structure which is used by the `Melding` state
 */
interface LiveMeldingState {
  liveMeldingen: LiveMeldingList;
}

/**
 * The initial values of the `live` state
 */
const initialState: LiveMeldingState = {
  liveMeldingen: [],
};

/**
 * A slice which handles all the `live` state management logic
 */
const liveSlice = createSlice({
  name: 'liveMelding',
  initialState,
  reducers: {
    setLiveMeldingen: (state, action: PayloadAction<LiveMeldingList>) => {
      state.liveMeldingen = action.payload;
    },
  },
});

/**
 * The actions available for the "live" feature
 */
export const { setLiveMeldingen } = liveSlice.actions;

/**
 * An instance of the "live" reducer which handles logic for each action
 */
export const liveReducer = liveSlice.reducer;
