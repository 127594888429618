import { makeStyles } from '@material-ui/core';

/**
 * The styles which are used by the `SidebarContent` component
 */
export default makeStyles({
  menuTitle: {
    textTransform: 'uppercase',
    '& span': {
      fontWeight: 'bold',
    },
  },
});
