import { useEffect } from 'react';

import { CssBaseline, ThemeProvider } from '@material-ui/core';
import analytics from 'react-ga';
import { Switch, Route, useLocation } from 'react-router-dom';

import { Layout } from '../Layout/Layout';

import { routes } from '~config/routes';
import { ThemeWrapper } from '~features';
import { useAppSelector } from '~hooks';
import { THEMES } from '~themes';
import { NotFoundPage } from '~views';

/**
 * A container that handles the global app routing
 *
 * @param props The standard properties which are always available
 *
 * @returns The `App` container
 */
export function App(): JSX.Element {
  const location = useLocation();
  const { activeTheme } = useAppSelector((state) => state.theme);

  /**
   * Handle google analytics change
   */
  useEffect(() => {
    analytics.pageview(window.location.pathname + window.location.search);
  }, [location]);

  return (
    <ThemeWrapper>
      <ThemeProvider theme={THEMES[activeTheme].theme}>
        <CssBaseline />
        <Layout>
          <Switch>
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
            <Route exact component={NotFoundPage} />
          </Switch>
        </Layout>
      </ThemeProvider>
    </ThemeWrapper>
  );
}
