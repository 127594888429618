import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * The different themes which are allowed
 */
export type NotificationSoundType = 'ON' | 'OFF';

/**
 * The types structure which is used by the `Notification` state
 */
interface NotificationsState {
  notificationSound: NotificationSoundType;
}

/**
 * The initial values of the `Notification` state
 */
const initialState: NotificationsState = {
  notificationSound: 'OFF',
};

/**
 * A slice which handles all the `Notification Sound` state management logic
 */
const notificationSoundSlice = createSlice({
  name: 'notification-sound',
  initialState,
  reducers: {
    setNotificationSound: (
      state,
      action: PayloadAction<Partial<NotificationSoundType>>,
    ) => {
      state.notificationSound = action.payload;
      localStorage.setItem('notification_sound', action.payload);
    },
  },
});

/**
 * The actions available for the "Notification Sound" feature
 */
export const { setNotificationSound } = notificationSoundSlice.actions;

/**
 * An instance of the "Notification Sound" reducer which handles logic for each action
 */
export const notificationSoundReducer = notificationSoundSlice.reducer;
