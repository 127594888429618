import {
  AppBar,
  IconButton,
  InputBase,
  Toolbar,
  useTheme,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  BrightnessHigh as LightThemeIcon,
  Brightness4 as DarkThemeIcon,
  VolumeOff as DisableSoundIcon,
  VolumeUp as EnableSoundIcon,
  Menu as MenuIcon,
} from '@material-ui/icons';

import useStyles from './Navbar.style';

import { setTheme, toggleSidebar } from '~features';
import { setNotificationSound } from '~features/notifications';
import { useAppDispatch, useAppSelector } from '~hooks';

/**
 * A component that handles user interactions at the top of the screen
 *
 * @param props The standard properties which are always available
 *
 * @returns The `Navbar` component
 */
export function Navbar(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { activeTheme } = useAppSelector((state) => state.theme);
  const { notificationSound } = useAppSelector(
    (state) => state.notificationSound,
  );

  /**
   * Handles the toggling of the theme
   */
  const handleThemeToggle = () =>
    dispatch(setTheme(activeTheme === 'DARK' ? 'LIGHT' : 'DARK'));

  /**
   * Handles the toggling of the notification sound
   */
  const handleNotificationToggle = () =>
    dispatch(setNotificationSound(notificationSound === 'OFF' ? 'ON' : 'OFF'));

  /**
   * Handles the toggling of the sidebar
   */
  const handleSidebarToggle = () => dispatch(toggleSidebar());

  return (
    <header>
      <AppBar position="fixed" className={classes.root} color="default">
        <Toolbar className={classes.toolbar}>
          <div className={classes.logo} />
          <IconButton
            onClick={handleSidebarToggle}
            aria-label="open zij-navigatie"
            className={classes.sidebarMenu}
          >
            <MenuIcon />
          </IconButton>
          {!!process.env.REACT_APP_ENABLE_SEARCH && (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                type="text"
                placeholder="Zoeken op meldingen"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'zoeken' }}
              />
            </div>
          )}
          <div className={classes.grow} />
          <IconButton onClick={handleThemeToggle}>
            {theme.palette.type === 'light' ? (
              <LightThemeIcon />
            ) : (
              <DarkThemeIcon />
            )}
          </IconButton>
          <IconButton onClick={handleNotificationToggle}>
            {notificationSound === 'ON' ? (
              <EnableSoundIcon />
            ) : (
              <DisableSoundIcon />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
    </header>
  );
}
