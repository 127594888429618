import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';

import useStyles from './FilterSelector.style';

import { getFilterIcon } from '~common/icons';
import { addUserFilter, removeUserFilter } from '~features';
import { useAppDispatch, useAppSelector } from '~hooks';
import { FilterItem } from '~models/api';
import { FilterType } from '~models/stream';
import { getAcceptedFields } from '~utils';

/**
 * The property types which are used by the `FilterSelector` component
 */
interface FilterSelectorProps {
  type: string;
  filterItems: FilterItem['items'];
}

/**
 * A component that handles the selecting of filter items
 *
 * @param props The standard properties which are always available
 * @param props.type The type of the filter which is being used
 * @param props.filterItems The filter items to iterate through
 *
 * @returns The `FilterSelector` component
 */
export function FilterSelector({ type, filterItems }: FilterSelectorProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { filtersApplied } = useAppSelector((state) => state.filter);

  /**
   * Get the icons that belong to the filter selector
   */
  const { selected: CheckedIcon, unselected: UncheckedIcon } = getFilterIcon(
    type as FilterType,
  );

  /**
   * Checks if a filter has been applied by the user
   */
  const isFilterApplied = (id: string) => {
    const includes = filtersApplied[type].includes(id);
    return includes;
  };

  /**
   * Handles the user selected filters and dispatches them to the state
   * @param id The id of the filter item to use
   * @param selected If the filter has been selected or not
   */
  const handleUserFilterSelection = (id: string, selected: boolean) => {
    if (selected && !isFilterApplied(id)) {
      dispatch(addUserFilter({ type, id }));
    } else {
      dispatch(removeUserFilter({ type, id }));
    }
  };

  return (
    <>
      {filterItems.map((filter: Record<string, any>) => {
        const fields = getAcceptedFields(filter);
        const active = isFilterApplied(filter._id);

        return (
          <FormControlLabel
            style={{ opacity: active ? 1 : 0.8 }}
            key={filter._id}
            className={classes.root}
            control={
              <Checkbox
                checked={active}
                checkedIcon={<CheckedIcon />}
                icon={<UncheckedIcon />}
                onChange={({ target }) =>
                  handleUserFilterSelection(filter._id, target.checked)
                }
              />
            }
            label={
              <Typography variant="button" color="initial">
                {fields.title}
              </Typography>
            }
          />
        );
      })}
    </>
  );
}
