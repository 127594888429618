import { Container, Typography, Box, Divider, Paper } from '@material-ui/core';

import { PRIVACY } from './data/privacy.data';
import useStyles from './PrivacyPage.style';

import { Meta } from '~components';

export function PrivacyPage() {
  const classes = useStyles();

  return (
    <>
      <Meta
        title="P2000Scanner | Privacy Verklaring"
        description="Op deze pagina is de privacy verklaring van P2000Scanner te vinden!"
        image="https://p2000scanner.nl/static/images/cover.jpg"
        url="https://p2000scanner.nl/privacy"
        twitterName="@ScannerP2000"
        type="page"
      />
      <Box p={2}>
        <Typography variant="h4" className={classes.title}>
          Privacybeleid
        </Typography>
        <Typography className={classes.subtitle}>
          Privacy policy voor P2000Scanner.nl
        </Typography>
        <Box pt={2}>
          <Divider />
        </Box>
        <Container>
          {PRIVACY.map((section, index) => (
            <Paper className={classes.section} key={`${index + 1})`}>
              <Typography variant="h5" className={classes.title}>
                {index + 1}) {section.title}
              </Typography>
              <Box my={1} />
              {section.description.map((paragraph) => (
                <Typography variant="body1" className={classes.content}>
                  {paragraph}
                </Typography>
              ))}
            </Paper>
          ))}
        </Container>
      </Box>
    </>
  );
}
