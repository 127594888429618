import { makeStyles } from '@material-ui/core';

/**
 * The styles which are used by the `DashboardPage` page
 */
export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    '& .MuiPaper-root': {
      padding: theme.spacing(2),
    },
  },
  bigNumber: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '3em',
  },
}));
