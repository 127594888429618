import { Box, Divider, Paper, Typography } from '@material-ui/core';

/**
 * The property types which are used by the `BigNumberCard` component
 */
interface BigNumberCardProps {
  title: string;
  number: number;
  size?: string;
}

/**
 * A component that displays a card with a title and a big number
 *
 * @param props The standard properties which are always available
 * @param props.title The title to display in the card
 * @param props.number The number to display in the card
 * @param props.size The font size to use
 *
 * @returns The `BigNumberCard` component
 */
export function BigNumberCard({
  title,
  number,
  size,
}: BigNumberCardProps): JSX.Element {
  return (
    <Paper>
      <Box textAlign="center">
        <Typography variant="button">{title}</Typography>
      </Box>
      <Box my={1}>
        <Divider />
      </Box>
      <Box fontWeight="bold" textAlign="center" fontSize={size}>
        {number}
      </Box>
    </Paper>
  );
}

/**
 * The default property values which are used by the `BigNumberCard` component
 */
BigNumberCard.defaultProps = {
  size: '3em',
};
