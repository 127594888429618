import {
  combineReducers,
  configureStore,
  EnhancedStore,
} from '@reduxjs/toolkit';

import {
  meldingReducer,
  filterReducer,
  themeReducer,
  appReducer,
  liveReducer,
} from './features';

import { notificationSoundReducer } from '~features/notifications';

/**
 * A combined list of reducers
 */
const reducers = combineReducers({
  app: appReducer,
  theme: themeReducer,
  melding: meldingReducer,
  filter: filterReducer,
  live: liveReducer,
  notificationSound: notificationSoundReducer,
});

/**
 * A function that configures the app store
 *
 * @param initialState The initial state to use
 *
 * @returns An instantiation of the application store
 */
function configureAppStore(initialState = {}): EnhancedStore {
  /**
   * Create the application store
   */
  return configureStore({
    reducer: reducers,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
  });
}

/**
 * An instance of the application store
 */
export const store = configureAppStore();

/**
 * A type that returns a dispatch of store
 */
export type AppDispatch = typeof store.dispatch;

/**
 * A type that retrieves the return type of the root state
 */
export type RootState = ReturnType<typeof reducers>;
