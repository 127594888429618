import { Box, Typography, useTheme } from '@material-ui/core';
import { LocationOff as NoLocationIcon } from '@material-ui/icons';
import { LatLng } from 'leaflet';
import { Map, TileLayer, Marker } from 'react-leaflet';

import useStyles from './CustomMap.style';

/**
 * The property types which are used by the `CustomMap` component
 */
type CustomMapProps = {
  location?: GlobalLocation;
};

/**
 * The default property values which are used by the `CustomMap` component
 */
const defaultProps: Partial<CustomMapProps> = {
  location: undefined,
};

/**
 * A component displays a custom map with a specific location
 *
 * @param props The standard properties which are always available
 * @param props.location The location to display on the map
 *
 * @returns The `CustomMap` component
 */
export function CustomMap({ location }: CustomMapProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  /**
   * Checks if the location is available
   */
  const hasLocation = !!location;

  if (hasLocation) {
    /**
     * The position of the marker to use
     */
    const markerPosition = new LatLng(
      location?.latitude as number,
      location?.longitude as number,
    );

    /**
     * The url to use when the application is in light mode
     */
    const LIGHT_THEME_URL =
      'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png';

    /**
     * The url to use when the application is in dark mode
     */
    const DARK_THEME_URL =
      'https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png';

    return (
      <Map
        center={markerPosition}
        zoom={16}
        minZoom={8}
        className={classes.map}
      >
        <TileLayer
          url={
            theme.palette.type === 'light' ? LIGHT_THEME_URL : DARK_THEME_URL
          }
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | Map: &copy; <a href="https://carto.com/">Carto</a>'
        />
        <Marker position={markerPosition} />
      </Map>
    );
  }

  return (
    <Box className={classes.noLocation}>
      <NoLocationIcon className={classes.icon} />
      <Typography className={classes.text} variant="body1">
        Geen locatie beschikbaar
      </Typography>
    </Box>
  );
}

/**
 * Assign the default properties to the `CustomMap` component
 */
CustomMap.defaultProps = defaultProps;
