import { useEffect, useState } from 'react';

import { Box, Divider, Paper, Typography } from '@material-ui/core';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';

import useStyles from './UpdatePage.style';

import { Meta } from '~components';
import { useApi } from '~hooks';
import { getDate } from '~utils';

/**
 * A page that displays update information about the application
 *
 * @param props The standard properties which are always available
 *
 * @returns The `UpdatePage` page
 */
export function UpdatePage(): JSX.Element {
  const classes = useStyles();
  const api = useApi();

  const [updates, setUpdates] = useState<Record<string, any>[]>();

  /**
   * Fetches the update data and sets it to the state
   */
  useEffect(() => {
    const fetch = async () => {
      const { data } = await api.get(`/updates`);
      setUpdates(data ?? undefined);
    };
    fetch();
  }, []);

  return (
    <>
      <Meta
        title="P2000Scanner | Updates"
        description="Op deze pagina zijn de recentste updates te zien van P2000Scanner!"
        image="https://p2000scanner.nl/static/images/cover.jpg"
        url="https://p2000scanner.nl/updates"
        twitterName="@ScannerP2000"
        type="page"
      />
      <Box p={2}>
        <Typography variant="h4" className={classes.title}>
          Updates
        </Typography>
        <Typography className={classes.subtitle}>
          Op deze pagina worden updates geplaatst zodat bezoekers de voortgang
          van P2000Scanner kunnen bijhouden.
        </Typography>
        <Box pt={2}>
          <Divider />
        </Box>
        <Box
          className={classes.root}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="column"
        >
          <Timeline className={classes.timeline}>
            {updates?.map((update, index) => {
              const isFirstItem = index === 0;
              const isLastItem = index + 1 < updates?.length;

              return (
                <TimelineItem key={update.version}>
                  <TimelineOppositeContent className={classes.sideContent}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="space-between"
                      flexDirection="column"
                    >
                      {getDate(update.date)}
                      <Typography variant="overline">
                        {update.version}
                      </Typography>
                    </Box>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot
                      variant={isFirstItem ? 'default' : 'outlined'}
                    />
                    {isLastItem && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper className={classes.paper}>
                      <Typography
                        className={classes.updateTitle}
                        variant="body1"
                      >
                        {update.description}
                      </Typography>
                      {update.changes.length > 0 && (
                        <ul>
                          {update.changes.map((change: string) => (
                            <li key={change}>{change}</li>
                          ))}
                        </ul>
                      )}
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </Box>
      </Box>
    </>
  );
}
