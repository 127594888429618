import { useTheme } from '@material-ui/core';
import { LatLng } from 'leaflet';
import { Map, TileLayer } from 'react-leaflet';

import useStyles from './LiveMapPage.style';

import { Meta } from '~components';
import { LiveMeldingMarkers } from '~components/LiveMeldingList/LiveMeldingList';
import { LiveMeldingWrapper } from '~features';
import { useAppSelector } from '~hooks';

/**
 * A page that displays "meldingen on a map"
 *
 * @param props The standard properties which are always available
 *
 * @returns The `LiveMapPage` page
 */

export function LiveMapPage() {
  const { liveMeldingen } = useAppSelector((state) => state.live);

  const classes = useStyles();
  const theme = useTheme();

  /**
   * MarkerPosition to place the map in the center
   */
  const markerPosition = new LatLng(52.1326332 as number, 5.291266 as number);

  /**
   * The url to use when the application is in light mode
   */
  const LIGHT_THEME_URL =
    'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png';

  /**
   * The url to use when the application is in dark mode
   */
  const DARK_THEME_URL =
    'https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png';

  return (
    <>
      <Meta
        title="P2000Scanner | Live Meldingen Kaart"
        description="Benieuwd waar alle meldingen zich in het land afspelen? Op deze pagina kun je alle meldingen volgen die gemaakt worden door de meldkamer. De meldingen worden getoond door een marker op de kaart."
        image="https://p2000scanner.nl/static/images/cover.jpg"
        url="https://p2000scanner.nl"
        twitterName="@ScannerP2000"
        type="page"
      />
      <LiveMeldingWrapper>
        <Map
          center={markerPosition}
          zoom={8}
          minZoom={8}
          className={classes.map}
        >
          <TileLayer
            url={
              theme.palette.type === 'light' ? LIGHT_THEME_URL : DARK_THEME_URL
            }
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | Map: &copy; <a href="https://carto.com/">Carto</a>'
          />
          {liveMeldingen.length > 0 && (
            <LiveMeldingMarkers liveMeldingen={liveMeldingen} />
          )}
        </Map>
      </LiveMeldingWrapper>
    </>
  );
}
