import { makeStyles } from '@material-ui/core';

import { SIDEBAR_WIDTH } from '~common/variables';

/**
 * The styles which are used by the `Sidebar` component
 */
export default makeStyles({
  drawerPaper: {
    width: SIDEBAR_WIDTH,
  },
});
