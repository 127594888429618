import { useEffect, useState } from 'react';

import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';
import dayjs from 'dayjs';

import useStyles from './DashboardPage.style';

import { BigNumberCard, CustomChart, Meta } from '~components';
import { useApi } from '~hooks';

/**
 * A page that currently does nothing except being a placeholder
 *
 * @param props The standard properties which are always available
 *
 * @returns The `DashboardPage` page
 */
export function DashboardPage() {
  const classes = useStyles();
  const api = useApi();

  const [dashboardData, setDashboardData] = useState<Record<string, any>>();
  const [chartData, setChartData] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const { data } = await api.get('/dashboard');
      setDashboardData(data ?? undefined);
      setChartData(
        data.hourly.map((hourly: any) => ({
          Aantal: hourly.amount,
          Datum: dayjs(hourly.date).format('HH:[00]'),
        })) ?? [],
      );
    };
    fetch();
  }, []);

  return (
    <>
      <Meta
        title="P2000Scanner | Dashboard"
        description="Op het P2000Scanner Dashboard staat relevante informatie over het aantal meldingen van de dag. Ook staat er een grafiek waarop je kunt zien hoeveel meldingen er per uur zijn geweest."
        image="https://p2000scanner.nl/static/images/cover.jpg"
        url="https://p2000scanner.nl/dashboard"
        twitterName="@ScannerP2000"
        type="page"
      />
      <Box className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <BigNumberCard
              title="Meldingen vandaag"
              number={dashboardData?.today || 0}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <BigNumberCard
              title="Meldingen gisteren"
              number={dashboardData?.yesterday || 0}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <BigNumberCard
              title="Meldingen afgelopen 7 dagen"
              number={dashboardData?.lastWeek || 0}
            />
          </Grid>

          <Grid item xs={12}>
            <Paper>
              <Typography variant="overline">
                Meldingen afgelopen 24 uur
              </Typography>
              <Box mb={2} mt={1}>
                <Divider />
              </Box>
              <Box height={400}>
                <CustomChart
                  data={chartData}
                  xKey="Datum"
                  yKey="Aantal"
                  yLabel="Aantal"
                />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="flex-start" spacing={3}>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <BigNumberCard
                  title="Brandweer meldingen vandaag"
                  number={dashboardData?.brandweer || 0}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <BigNumberCard
                  title="Ambulance meldingen vandaag"
                  number={dashboardData?.ambulance || 0}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <BigNumberCard
                  title="Politie meldingen vandaag"
                  number={dashboardData?.politie || 0}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <BigNumberCard
                  title="Lifeliner meldingen vandaag"
                  number={dashboardData?.lifeliner || 0}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <BigNumberCard
                  title="Test meldingen vandaag"
                  number={dashboardData?.test || 0}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <BigNumberCard
                  title="Onbekende meldingen vandaag"
                  number={dashboardData?.onbekend || 0}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
