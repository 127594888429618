import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FilterDetails } from '~models/api';
import { makeObjectWithValues } from '~utils';

/**
 * The types structure which is used by the `Filter` state
 */
interface FilterState {
  isOpen: boolean;
  filters: FilterDetails[];
  filterMenusOpen: Record<string, boolean>;
  filtersApplied: Record<string, string[]>;
}

/**
 * The initial values of the `Filter` state
 */
const initialState: FilterState = {
  isOpen: false,
  filters: [],
  filterMenusOpen: {},
  filtersApplied: {},
};

/**
 * A slice which handles all the `Filter` state management logic
 */
const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    openFilter: (state) => {
      state.isOpen = true;
    },
    closeFilter: (state) => {
      state.isOpen = false;
    },
    openFilterMenu: (state, action: PayloadAction<string>) => {
      state.filterMenusOpen = {
        ...state.filterMenusOpen,
        [action.payload]: true,
      };
    },
    closeFilterMenu: (state, action: PayloadAction<string>) => {
      state.filterMenusOpen = {
        ...state.filterMenusOpen,
        [action.payload]: false,
      };
    },
    setFilterItems: (state, action: PayloadAction<FilterState['filters']>) => {
      state.filters = action.payload;
      state.filterMenusOpen = makeObjectWithValues(
        action.payload,
        'type',
        false,
      );
      state.filtersApplied = makeObjectWithValues(action.payload, 'type', []);
    },
    addUserFilter: (
      state,
      action: PayloadAction<{ type: string; id: string }>,
    ) => {
      state.filtersApplied = {
        ...state.filtersApplied,
        [action.payload.type]: [
          ...state.filtersApplied[action.payload.type],
          action.payload.id,
        ],
      };
    },
    removeUserFilter: (
      state,
      action: PayloadAction<{ type: string; id: string }>,
    ) => {
      state.filtersApplied = {
        ...state.filtersApplied,
        [action.payload.type]: state.filtersApplied[action.payload.type].filter(
          (filterId) => filterId !== action.payload.id,
        ),
      };
    },
  },
});

/**
 * The actions available for the filter feature
 */
export const {
  openFilter,
  closeFilter,
  openFilterMenu,
  closeFilterMenu,
  setFilterItems,
  addUserFilter,
  removeUserFilter,
} = filterSlice.actions;

/**
 * An instance of the filter reducer which handles the logic for each action
 */
export const filterReducer = filterSlice.reducer;
