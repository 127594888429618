import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
  },
  section: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  subtitle: {
    paddingTop: theme.spacing(1),
    textAlign: 'left',
  },
  content: {
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(2),
    },
  },
}));
