import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

import { GLOBAL_STYLES } from './globals';

/**
 * The dark theme used throughout the application
 */
export const DARK_THEME = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: '#eee',
      },
      secondary: {
        main: '#efefef',
      },
      background: {
        default: '#121212',
        paper: '#1d1d1d',
      },
      text: {
        primary: '#ddd',
      },
      type: 'dark',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          ...GLOBAL_STYLES('dark'),
        },
      },
    },
  }),
);
