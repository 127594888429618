import { ToastContainerProps } from 'react-toastify';

/**
 * The toast configuration to use
 */
export const toastConfig: ToastContainerProps = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: true,
};
