import { makeStyles } from '@material-ui/core';

/**
 * The styles which are used by the `FilterToggler` component
 */
export default makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));
