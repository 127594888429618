import { makeStyles } from '@material-ui/core';

/**
 * The styles which are used by the `FilterItem` component
 */
export default makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2, 3),
  },
  subject: {
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(2),
    },
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));
