import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ThemeType } from '~themes';

/**
 * The types structure which is used by the `Theme` state
 */
interface ThemeState {
  activeTheme: ThemeType;
}

/**
 * The initial values of the `Theme` state
 */
const initialState: ThemeState = {
  activeTheme: 'LIGHT',
};

/**
 * A slice which handles all the `Theme` state management logic
 */
const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<Partial<ThemeType>>) => {
      state.activeTheme = action.payload;
      localStorage.setItem('selected_theme', action.payload);
    },
  },
});

/**
 * The actions available for the "theme" feature
 */
export const { setTheme } = themeSlice.actions;

/**
 * An instance of the "theme" reducer which handles logic for each action
 */
export const themeReducer = themeSlice.reducer;
