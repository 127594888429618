import { makeStyles } from '@material-ui/core';

/**
 * The styles which are used by the `MeldingDetailsPage` page
 */
export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  map: {
    minHeight: 400,
    height: '100%',
  },
  noLocation: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  view: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  accordion: {
    margin: theme.spacing(1, 0),
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    '& > .MuiDivider-root': {
      margin: theme.spacing(1.5, 0),
    },
  },
  title: {
    fontWeight: 'bold',
    margin: theme.spacing(2, 0),
    wordWrap: 'break-word',
  },
  cardWrapper: {
    flexGrow: 1,
  },
  tags: {
    margin: theme.spacing(1, 0),
    '& > .MuiChip-root': {
      marginRight: theme.spacing(1),
    },
  },
  media: {
    width: '100%',
  },
  share: {
    margin: theme.spacing(1, 0),
    '& > .react-share__ShareButton': {
      marginRight: theme.spacing(1),
    },
  },
}));
