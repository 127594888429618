/**
 * Retrieves tags from a "melding" object (like "cruciaal", "contact" or "test"),
 * if all tags are disabled the most important one goes first
 * @param melding The "melding" object to retrieve the tags from
 * @returns An object with the tags
 */
export const getMeldingTags = (
  melding: Record<string, any>,
  important: 'cruciaal' | 'test' | 'contact',
) => {
  const tags = Object.keys(melding)
    .filter((key) => typeof melding[key] === 'boolean')
    .map((key) => ({ type: key, enabled: melding[key] }))
    .sort((tag) => (tag.enabled ? -1 : 1));

  if (tags.every((tag) => !tag.enabled)) {
    tags.sort((tag) => (tag.type === important ? -1 : 1));
  }

  return tags;
};

/**
 * Retrieves the location (in latitude and longitude) from a "melding"
 * @param melding The "melding" to use the data from
 * @returns The Lat/Long location from the "melding" location
 */
export const getMeldingLocation = (
  melding: Record<string, any>,
): GlobalLocation | undefined => {
  const isAdres = !!melding.adres;
  const isSnelweg = !!melding.snelweg;

  /**
   * Retrieves the latitude from a "melding" location
   * @returns The latitude of the location
   */
  const getLatitude = (): number | undefined => {
    if (isAdres) {
      return melding.adres.latitude;
    }
    if (isSnelweg) {
      return melding.snelweg.latitude;
    }
    return undefined;
  };

  /**
   * Retrieves the longitude from a "melding" location
   * @returns The longitude of the location
   */
  const getLongitude = (): number | undefined => {
    if (isAdres) {
      return melding.adres.longitude;
    }
    if (isSnelweg) {
      return melding.snelweg.longitude;
    }
    return undefined;
  };

  const latitude = getLatitude();
  const longitude = getLongitude();

  return !!latitude && !!longitude
    ? {
        latitude,
        longitude,
      }
    : undefined;
};

/**
 * Retrieves the location (in latitude and longitude) from a "lifeliner melding"
 * @param lifeliner The "lifeliner" to use the data from
 * @returns The Lat/Long location from the "lifeliner" location
 */
export const getLifelinerLocation = (
  lifeliner: Record<string, any>,
): GlobalLocation | undefined => {
  /**
   * Retrieves the latitude from a "lifeliner" location
   * @returns The latitude of the location
   */
  const getLatitude = (): any | undefined => {
    const ref = lifeliner?.acList[0]?.Lat;
    if (!ref) {
      return undefined;
    }
    return ref;
  };

  /**
   * Retrieves the longitude from a "lifeliner" location
   * @returns The longitude of the location
   */
  const getLongitude = (): any | undefined => {
    const ref = lifeliner?.acList[0]?.Long;
    if (!ref) {
      return undefined;
    }
    return ref;
  };

  const latitude = getLatitude();
  const longitude = getLongitude();

  return !!latitude && !!longitude
    ? {
        latitude,
        longitude,
      }
    : undefined;
};
