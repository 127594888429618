import { makeStyles } from '@material-ui/core';

/**
 * The styles which are used by the `Capcode` component
 */
export default makeStyles((theme) => ({
  root: {
    borderLeft: '2px solid rgba(0, 0, 0, 0.2)',
    padding: theme.spacing(0.5, 0),
    paddingLeft: theme.spacing(1),
    wordWrap: 'break-word',
    '& span:not(:empty)': {
      marginRight: 5,
    },
  },
  capcode: {
    fontWeight: 'bold',
  },
  description: {
    fontStyle: 'italic',
  },
}));
