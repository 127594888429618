import {
  Category as DashboardPageIcon,
  AspectRatioRounded as MonitorPageIcon,
  GetApp as UpdatePageIcon,
  VpnLock as PrivacyPageIcon,
  Map as LiveMapPageIcon,
  Email as ContactPageIcon,
  GroupWorkRounded as RoadmapPageIcon,
} from '@material-ui/icons';

import {
  ContactPage,
  DashboardPage,
  MeldingDetailsPage,
  MonitorPage,
  PrivacyPage,
  RoadmapPage,
  UpdatePage,
} from '~views';
import { LiveMapPage } from '~views/LiveMapPage/LiveMapPage';

/**
 * The routes that will be used for navigation throughout the application
 */
export const routes: Route[] = [
  {
    path: '/dashboard',
    component: DashboardPage,
    exact: true,
    displayName: 'Dashboard',
    displayLocation: 'sidebar',
    icon: DashboardPageIcon,
  },
  {
    path: '/',
    component: MonitorPage,
    exact: true,
    displayName: 'Monitor',
    displayLocation: 'sidebar',
    icon: MonitorPageIcon,
  },
  {
    path: '/live',
    component: LiveMapPage,
    exact: true,
    displayName: 'Live Map',
    displayLocation: 'sidebar',
    icon: LiveMapPageIcon,
  },
  {
    path: '/updates',
    component: UpdatePage,
    exact: true,
    displayName: 'Updates',
    displayLocation: 'sidebar',
    icon: UpdatePageIcon,
  },
  {
    path: '/roadmap',
    component: RoadmapPage,
    exact: true,
    displayName: 'Roadmap',
    displayLocation: 'sidebar',
    icon: RoadmapPageIcon,
  },
  {
    path: '/privacy',
    component: PrivacyPage,
    exact: true,
    displayName: 'Privacybeleid',
    displayLocation: 'sidebar',
    icon: PrivacyPageIcon,
  },
  {
    path: '/melding/:id',
    component: MeldingDetailsPage,
    exact: true,
    displayName: 'Melding Details',
    displayLocation: 'none',
  },
  {
    path: '/contact',
    component: ContactPage,
    exact: true,
    displayName: 'Contact',
    displayLocation: 'sidebar',
    icon: ContactPageIcon,
  },
];
