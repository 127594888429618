import { makeStyles } from '@material-ui/core';

import { SIDEBAR_WIDTH } from '~common/variables';

/**
 * The styles which are used by the `Layout` container
 */
export default makeStyles((theme) => ({
  content: {
    marginTop: 56,
    minHeight: `calc(100vh - 56px)`,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      marginTop: 48,
      minHeight: `calc(100vh - 48px)`,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 64,
      minHeight: `calc(100vh - 64px)`,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: SIDEBAR_WIDTH,
    },
  },
}));
