import { makeStyles } from '@material-ui/core';

/**
 * The size ratio for an image (16 by 9)
 */
const IMAGE_RATIO = '56.25%';

/**
 * The styles which are used by the `VehicleCard` component
 */
export default makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  header: {
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
  },
  media: {
    height: 0,
    paddingTop: IMAGE_RATIO,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: theme.spacing(1, 2),
    flexGrow: 1,
    '& .MuiTypography-overline': {
      fontWeight: 'bold',
      lineHeight: 0,
    },
    '& .MuiTypography-body1': {
      marginBottom: theme.spacing(1.5),
    },
  },
  details: {
    paddingTop: theme.spacing(1),
  },
  caption: {
    fontStyle: 'italic',
    opacity: 0.5,
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));
