import { makeStyles } from '@material-ui/core';

/**
 * The styles which are used by the `Melding` component
 */
export default makeStyles((theme) => ({
  root: {
    borderLeft: `6px solid black`,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(2),
    },
  },
  titel: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  details: {
    paddingTop: theme.spacing(1),
    opacity: 0.5,
    '& span': { fontWeight: 'bold' },
    '& > div:nth-of-type(1)': {
      textAlign: 'left',
    },
    '& > div:nth-of-type(2)': {
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'right',
      },
    },
    '& > div:nth-of-type(3)': {
      textAlign: 'right',
    },
  },
}));
