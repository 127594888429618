import { FilterItem } from '~models/api';

/**
 * Extracts accepted fields from an object
 * @param filterItem The filter item to retrieve the fiels from
 * @returns The accepted fields with their data
 */
export const getAcceptedFields = (
  filterItem: Record<string, any>,
): { id: string; title: string } => {
  const { _id, naam } = filterItem;
  return { id: _id, title: naam };
};

/**
 * Checks if a filter item has nested filter items
 * @param filterItems The filter items to check if it has nested items
 * @returns If a filter items list contains nested filter items
 */
export const hasNestedFilterItems = (filterItems: FilterItem) =>
  filterItems.items.every((item) => Array.isArray(item?.items));
