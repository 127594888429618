import { makeStyles } from '@material-ui/core';

/**
 * The styles which are used by the `UpdatePage` page
 */
export default makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
  },
  subtitle: {
    paddingTop: theme.spacing(1),
    textAlign: 'left',
  },
  timeline: {
    [theme.breakpoints.up('md')]: {
      width: '120ch',
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.5),
    },
  },
  backgroundGreen: {
    backgroundColor: 'green',
  },
  backgroundOrange: {
    backgroundColor: 'orange',
  },
  backgroundBlack: {
    backgroundColor: 'black',
  },
  backgroundGrey: {
    backgroundColor: 'gray',
  },
  paper: {
    padding: theme.spacing(2),
    width: 'auto',
  },
  updateTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  sideContent: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '40ch',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 65,
    },
  },
}));
